<template>
  <div style="height: 100%; background: #f2f2f2">
    <section class="jq22-flexView">
      <div class="jq22-head-read">
        <div class="jq22-flex">
          <div class="jq22-read-img">
            <img :src="user.user ? user.user.image : ''" alt="" />
          </div>
          <div class="jq22-flex-box">
            {{ user.user ? user.user.username : "暂无" }}
          </div>
        </div>
      </div>
    </section>

    <section class="jq22-scrollView">
      <div class="jq22-palace jq22-palace-two jq22-palace-line">
        <router-link
          :to="{ name: 'userOrders', params: { status: 1 } }"
          class="jq22-palace-grid"
        >
          <div class="jq22-palace-grid-icon">
            <img
              src="https://cdn.yukongkeji.cn/zaolu/20211225/f5f0c9d3af3f07127e05269ad72429b8.png"
              alt=""
            />
          </div>

          <div class="jq22-palace-grid-text">
            <h2>待付款</h2>
            <div class="num" v-if="user.obligationCount">
              {{ user.obligationCount }}
            </div>
          </div>
        </router-link>

        <router-link
          :to="{ name: 'userOrders', params: { status: 2 } }"
          class="jq22-palace-grid"
        >
          <div class="jq22-palace-grid-icon">
            <img
              src="https://cdn.yukongkeji.cn/zaolu/20211225/6a96ec0594b4a6225823163ceae776a3.png"
              alt=""
            />
          </div>

          <div class="jq22-palace-grid-text">
            <h2>待发货</h2>
            <div class="num" v-if="user.dropshippingCount">
              {{ user.dropshippingCount }}
            </div>
          </div>
        </router-link>

        <router-link
          :to="{ name: 'userOrders', params: { status: 3 } }"
          class="jq22-palace-grid"
        >
          <div class="jq22-palace-grid-icon">
            <img
              src="https://cdn.yukongkeji.cn/zaolu/20211225/758b3a0218bd39d22fc8a26f4cbc597a.png"
              alt=""
            />
          </div>

          <div class="jq22-palace-grid-text">
            <h2>待收货</h2>
            <div class="num" v-if="user.confirmCount">
              {{ user.confirmCount }}
            </div>
          </div>
        </router-link>

        <router-link
          :to="{ name: 'userOrders', params: { status: 4 } }"
          class="jq22-palace-grid"
        >
          <div class="jq22-palace-grid-icon">
            <img
              src="https://cdn.yukongkeji.cn/zaolu/20211225/dd6ae18be06d3907088ed11c652dd169.png"
              alt=""
            />
          </div>

          <div class="jq22-palace-grid-text">
            <h2>已完成</h2>
          </div>
        </router-link>

        <router-link
          :to="{ name: 'userOrders', params: { status: 5 } }"
          class="jq22-palace-grid"
        >
          <div class="jq22-palace-grid-icon">
            <img
              id="tuikuan"
              src="https://cdn.yukongkeji.cn/zaolu/20211225/3d188d684b80b35bcac73a3cb398009f.png"
              alt=""
            />
          </div>

          <div class="jq22-palace-grid-text">
            <h2>退款/售后</h2>
            <div class="num" v-if="user.refundCount">
              {{ user.refundCount }}
            </div>
          </div>
        </router-link>
        <router-link class="jq22-palace-grid" to="/user/address">
          <div class="jq22-palace-grid-icon">
            <img
              src="https://cdn.yukongkeji.cn/zaolu/20211225/9c79ac59ba6554e57d49dcd3842d0641.png"
              alt=""
            />
          </div>

          <div class="jq22-palace-grid-text">
            <h2>地址管理</h2>
          </div>
        </router-link>
        <a class="jq22-palace-grid">
          <div class="jq22-palace-grid-icon">
            <img
              src="https://cdn.yukongkeji.cn/zaolu/20211225/1895ceb7a28a1932d2a939b56dd23b58.png"
              alt=""
            />
          </div>
          <div class="jq22-palace-grid-text" @click="clearCache">
            <h2>清除缓存</h2>
          </div>
        </a>
      </div>
    </section>

    <div class="jq-footer-fixed">
      <a
        class="jq22-tabBar-item right"
        id="tabBar-item1"
        @click="$router.replace('/')"
      >
        <span class="jq22-tabBar-item-icon">
          <i class="iconfont iconfont-img"
            ><img
              id="home"
              src="https://cdn.yukongkeji.cn/zaolu/20211225/46d0e21ec73a902e31407f4048c410fa.png"
              alt=""
          /></i>
        </span>

        <span class="jq22-tabBar-item-text">商品</span>
      </a>
      <a class="jq22-tabBar-item" id="tabBar-item2">
        <span class="jq22-tabBar-item-icon">
          <i class="iconfont iconfont-img"
            ><img
              id="my"
              src="https://cdn.yukongkeji.cn/zaolu/20211225/40ff7ea07d1db11d40aa18d71d822961.png"
              alt=""
          /></i>
        </span>
        <span id="mytabBar" class="jq22-tabBar-item-text active">个人中心</span>
      </a>
    </div>
  </div>
</template>

<script>
import { SET_USER_INFO_MUTATION } from "../mutation-types";
import { Toast } from "vant";
import { Api } from "../services/api";
export default {
  name: "User",
  data() {
    return {};
  },
  created() {
    this.isVip()
  },
  methods: {
    clearCache() {
      Toast.loading("清除缓存中...");
      Api.clearCache().then(() => {
        Toast.clear();
        this.$store.commit({
          type: SET_USER_INFO_MUTATION,
          payload: null,
        });
        this.fetchUser();
      });
    },
    fetchUser(callback) {
      Api.getUserCenter().then((data) => {
        callback && callback();
        this.$store.commit({
          type: SET_USER_INFO_MUTATION,
          payload: data.data,
        });
      });
    },
    isVip(){
      Api.isVip().then((res) => {
        console.log(111)
        if (res.data === 0) {
          this.$router.push({
            path: "/user/vip",
          });
        }
      });
    },
  },
  computed: {
    user() {
      return this.$store.state.user || {};
    },
  },
  mounted() {
    setTimeout(()=>{
      this.fetchUser();
    },500)

  },
};
</script>

<style scoped>
body {
  height: 100%;
}
img {
  border: 0;
}

.jq22-palace-line {
  position: relative;
}
.jq22-palace {
  /* margin: 0.5rem 0; */
  padding: 0.6rem 0;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.jq22-palace-two .jq22-palace-grid {
  width: 25%;
}
a:link {
  text-decoration: none;
}

.jq22-palace-grid {
  position: relative;
  float: left;
  padding: 1px;
  width: 20%;
  box-sizing: border-box;
  margin: 5px 0;
}

.jq22-palace-line .jq22-palace-grid:not(:nth-child(4n)):before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  height: 70%;
  border-right: 1px solid #e6e6e6;
  -webkit-transform: scaleX(0.5);
  transform: scaleX(0.5);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.jq22-palace-grid-icon {
  width: 28px;
  height: 28px;
  margin: 0 auto;
}

.jq22-palace-grid-text {
  display: block;
  text-align: center;
  color: #333333;
  font-size: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 0.2rem;
}

.jq22-palace-grid .num {
  position: absolute;
  top: 0;
  right: 1.42rem;
  font-size: 0.28rem;
  width: 15px;
  height: 15px;
  line-height: 15px;
  display: block;
  text-align: center;
  border-radius: 25px;
  color: #fff;
  background-color: #e93b3d;
  border: 1px solid #e93b3d;
}
.jq22-palace-grid-text h2 {
  font-size: 0.9rem;
  font-weight: normal;
  color: #333333;
  margin: 0;
  padding: 0;
}

.jq-footer-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 49;
  width: 100%;
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  background: #fff;
  padding-top: 3px;
}

.jq22-tabBar-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #979797;
}
.jq22-tabBar-item-text {
  display: inline-block;
  font-size: 14px;
  color: #818085;
  padding-top: 1px;
}

.active {
  color: #fe3a21;
}

.jq22-palace-grid-icon img {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}
.header {
  width: 100%;
  height: 44px;
  background-color: #fff;
  display: flex;
  text-align: center;
  font-family: "SimHei";
  font-weight: 600;
  border-bottom: 1px solid #ededed;
}

.jq22-flexView {
  width: 100%;
  margin: 0 auto;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #f2f2f2;
}

.jq22-head-read {
  background: -webkit-linear-gradient(left, #fd673d, #ff1e0f);
  background: -o-linear-gradient(right, #fd673d, #ff1e0f);
  background: -moz-linear-gradient(right, #fd673d, #ff1e0f);
  background: linear-gradient(to right, #fd673d, #ff1e0f);
  background-color: #fd673d;
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;
}

.jq22-read-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 0.4rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.jq22-flex-box {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  min-width: 0;
  font-size: 14px;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

.jq22-head-read {
  background: -webkit-linear-gradient(left, #fd673d, #ff1e0f);
  background: -o-linear-gradient(right, #fd673d, #ff1e0f);
  background: -moz-linear-gradient(right, #fd673d, #ff1e0f);
  background: linear-gradient(to right, #fd673d, #ff1e0f);
  background-color: #fd673d;
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;
}

.jq22-flex {
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px;
  position: relative;
}

.jq22-read-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 0.4rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

img {
  width: 100%;
  height: auto;
  display: block;
  border: 0;
}

.jq22-flex-box {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
}

.jq-footer-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 49;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  background: #fff;
  padding-top: 3px;
}

.iconfont-img img {
  width: 23px;
}

.right {
  border-right: 1px solid #ededed;
}

.jq22-palace-grid .num {
  position: absolute;
  top: 0;
  right: 1.42rem;
  font-size: 0.28rem;
  width: 15px;
  height: 15px;
  line-height: 15px;
  display: block;
  text-align: center;
  border-radius: 25px;
  color: #fff;
  background-color: #e93b3d;
  border: 1px solid #e93b3d;
}
</style>
