<template>
  <Nav title="申请退款" />
  <div v-if="order" style="padding-top: 50px">
    <div class="info-row">
      <div class="left-img">
        <img :src="order.image" alt="" />
      </div>
      <div class="right-info">
        <div class="info-title" style="color: black">{{ order.name }}</div>

        <div class="info-specification">{{ order.sname }}</div>

        <div class="info-price-row">
          <div class="info-price">
            ￥<span id="info-price">{{ order.price }}</span>
          </div>

          <div class="info-num">x{{ order.num }}</div>
        </div>
      </div>
    </div>

    <!--退款信息-->
    <div class="refund-detail">
      <div class="refund-ds">退款金额：￥{{ order.zprice }}</div>
      <div class="refund-ds">
        退款原因：
        <input
          class="cause-input"
          type="text"
          name="reason"
          v-model="reason"
          placeholder="请输入退款原因"
        />
      </div>
      <div class="refund-ds" v-show="logisticsShow">
        物流单号：
        <input
          class="cause-input"
          type="text"
          name="logistics"
          v-model="logistics"
          placeholder="请输入物流单号"
        />
      </div>
    </div>
    <div style="padding: 0 20px">
      <van-button
        @click="submit()"
        type="primary"
        class="login-btn"
        style="margin-top: 20px"
        :loading="loading"
        block
      >
        提交
      </van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import Nav from "./common/Nav.vue";
import { Api } from "../services/api";
import { CLEAR_USER_ORDER_LIST } from "../mutation-types";
export default {
  name: "RefundConfirm",
  data() {
    return {
      order: null,
      reason: "",
      loading: false,
      logistics: "",
      logisticsShow: !!this.$route.query.logistics,
    };
  },
  mounted() {
    Api.getOrderDetail(this.id).then((res) => {
      if (res.data.status !== 2 && res.data.status !== 3) {
        this.$router.replace("/user/orders/1");
      } else {
        this.order = res.data;
      }
    });
  },
  components: {
    Nav,
  },
  computed: {
    id: function () {
      return this.$route.params.id;
    },
  },
  methods: {
    async submit() {
      if (!this.reason) {
        Toast.fail("请输入退款原因");
        return;
      }
      this.loading = true;
      const res = await Api.refund({
        id: this.id,
        reason: this.reason,
        logistics: this.logistics,
      });
      this.loading = false;
      if (res.status === 1) {
        Toast.success("申请成功");
        this.$store.commit({
          type: CLEAR_USER_ORDER_LIST,
        });
        this.$router.replace("/user/orders/5");
      } else {
        Toast.fail(res.msg);
      }
    },
  },
};
</script>

<style scoped>
.info-row {
  background-color: #fff;
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.refund-detail {
  background-color: #fff;
}

.refund-ds {
  border: none;
  padding: 10px;
}

.cause-input {
  border: none;
  min-height: 20px;
  min-width: 100px;
}
.left-img {
  height: 90px;
  width: 90px;
}

.left-img img {
  width: 100%;
  height: auto;
}
.for-but {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
</style>
