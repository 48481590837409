<template>
  <Nav title="收货地址" />
  <div
    v-if="!userAddressList"
    style="text-align: center; width: 100%; margin-top: 60px"
  >
    <van-loading type="circular">加载中...</van-loading>
  </div>
  <div style="padding-top: 50px">
    <div class="info" v-for="address in userAddressList" :key="address.id">
      <div class="info-row" @click="select(address.id)">
        <span class="info-name">{{ address.username }}</span>
        <span class="info-phone">{{ address.phone }}</span>
        <span class="addr-default" v-if="address.status === 1">默认</span>
      </div>
      <div class="info-address">地址：{{ address.addr }}</div>
      <div class="btn-row">
        <div
          v-if="address.status !== 1"
          @click="setDefaultAddress(address.id)"
          class="btn-row-left btn-row-left1"
        >
          设为默认
        </div>
        <div class="btn-row-right">
          <span @click="$router.push(`/user/address/${address.id}`)">编辑</span>
          <div
            style="margin-left: 10px; margin-right: 10px"
            @click="deleteAddress(address.id)"
          >
            删除
          </div>
        </div>
      </div>
    </div>
  </div>

  <router-link to="/user/address/add" class="addnew">
    <img
      src="https://cdn.yukongkeji.cn/zaolu/20211225/3043f5dbe7cd4e5200abd16869d2f5df.png"
      alt=""
    />
    <span>添加地址</span>
  </router-link>
</template>

<script>
import { Dialog, Toast } from "vant";
import Nav from "./common/Nav.vue";
import {
  SET_USER_ADDRESS_LIST_MUTATION,
  CHANGE_USER_ADDRESS_DEFAULT_MUTATION,
} from "../mutation-types";
import { Api } from "../services/api";

export default {
  setup() {
    return {};
  },
  data() {
    return {};
  },
  components: {
    Nav,
  },
  mounted() {
    this.getAddressList();
  },
  computed: {
    userAddressList() {
      return this.$store.state.userAddressList;
    },
  },
  methods: {
    deleteAddress(id) {
      Dialog.confirm({
        message: "确定要删除该地址吗？",
      })
        .then(() => {
          Api.deleteAddress(id).then((res) => {
            if (res.status === 1) {
              this.getAddressList();
              Toast.success("删除成功");
            } else {
              Toast.fail("删除失败");
            }
          });
        })
        .catch(() => {
          console.log("cancel");
        });
    },
    select(id) {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      Api.setDefaultAddress(id)
        .then((res) => {
          Toast.clear();
          if (res.status === 1) {
            this.$store.commit({
              type: CHANGE_USER_ADDRESS_DEFAULT_MUTATION,
              payload: id,
            });
            if (this.$route.query.select) {
              this.$router.back();
            }
          } else {
            Toast.fail(res.msg);
          }
        })
        .catch(() => {
          Toast.clear();
        });
    },
    setDefaultAddress(id) {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      Api.setDefaultAddress(id)
        .then((res) => {
          Toast.clear();
          if (res.status === 1) {
            this.$store.commit({
              type: CHANGE_USER_ADDRESS_DEFAULT_MUTATION,
              payload: id,
            });
          } else {
            Toast.fail(res.msg);
          }
        })
        .catch(() => {
          Toast.clear();
        });
    },
    getAddressList() {
      Api.getAddressList().then((res) => {
        this.$store.commit({
          type: SET_USER_ADDRESS_LIST_MUTATION,
          payload: res.data,
        });
      });
    },
  },
};
</script>

<style scoped>
.addr-default {
  color: #d8585e;
  border: 1px solid #d8585e;
  text-align: center;
  border-radius: 20px;
  padding: 2px 5px;
  margin-left: 10px;
  font-size: 12px;
}

.info-address {
  font-size: 14px;
  color: #666666;
  border-bottom: 1px solid #ededed;
  margin: 0 auto;
  padding: 0 0 5px;
  text-align: left;
}

.info {
  margin-top: 5px;
  font-size: 15px;
  background-color: #fff;
  padding-bottom: 10px;
}

.info-row {
  display: flex;
  padding: 5px 10px 3px;
}
.info-name {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
}
.info-phone {
  font-size: 14px;
  padding-left: 10px;
  color: #666666;
}
.info-address {
  font-size: 14px;
  color: #666666;
  border-bottom: 1px solid #ededed;
  margin: 0 auto;
  padding: 0 0 5px 10px;
}
.btn-row {
  display: flex;
}
.btn-row-left {
  color: #d8585e;
  border: 1px solid #d8585e;
  text-align: center;
  border-radius: 20px;
  margin-top: 10px;
  margin-left: 10px;
  padding: 2px 5px;
  font-size: 12px;
}
.btn-row-left1 {
  color: #fff;
  background-color: #d8585e;
  border: 1px solid #eb5c63;
}
.btn-row-right {
  color: #666666;
  display: flex;
  padding-top: 10px;
  flex: 1;
  justify-content: flex-end;
}
.addnew {
  background-color: #d8585e;
  color: #fff;
  font-size: 15px;
  display: flex;
  width: 60%;
  padding: 5px 10px;
  border-radius: 20px;
  justify-content: center;
  margin: 20px auto;
}
.addnew img {
  width: 23px;
  height: 23px;
}
.addnew span {
  padding-top: 2px;
  padding-left: 2px;
}
</style>
