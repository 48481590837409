export const SET_PRODUCT_MUTATION = "SET_PRODUCT_MUTATION";
export const SET_CURRENT_WARE_SPEC_MUTATION = "SET_CURRENT_WARE_SPEC_MUTATION";

export const SET_USER_INFO_MUTATION = "SET_USER_INFO_MUTATION";
export const SET_USER_ADDRESS_LIST_MUTATION = "SET_USER_ADDRESS_LIST_MUTATION";

export const CHANGE_USER_ADDRESS_DEFAULT_MUTATION =
  "CHANGE_USER_ADDRESS_DEFAULT_MUTATION";

export const SET_USER_ORDER_LIST_MUTATION = "SET_USER_ORDER_LIST_MUTATION";

export const ADD_BUY_COUNT_MUTATION = "ADD_BUY_COUNT_MUTATION";
export const SUB_BUY_COUNT_MUTATION = "SUB_BUY_COUNT_MUTATION";

export const SET_SALE_INFO_MUTATION = "SET_SALE_INFO_MUTATION";

export const SET_MANAGER_PAGE_POSITION = "SET_MANAGER_PAGE_POSITION";

export const CLEAR_USER_ORDER_LIST = "CLEAR_USER_ORDER_LIST";