<template>
  <div class="container" v-if="user.uid">
    <div class="top">
      <div class="userinfo">
        <van-image
          :src="user.image"
          width="50"
          height="50"
          :round="true"
          class="avatar"
        />
        <div class="username">{{ user.username }}</div>
      </div>
    </div>
    <div class="integral">
      <div class="integralItem">
        <div @click="$router.push('/saleman/bill')" class="value">
          {{ user.rel_money }}
        </div>
        <div @click="$router.push('/saleman/bill')" class="key">
          <span style="position: relative">可兑积分</span>
          <span class="detail">明细</span>
        </div>
      </div>
      <div class="integralItem">
        <div class="value">{{ user.fast_money }}</div>
        <div class="key">在途积分</div>
      </div>
      <div class="integralItem"></div>
    </div>
    <div class="tab">
      <div class="tabItem">
        <van-image
          @click="$router.push('/saleman/manager')"
          src="https://cdn.yukongkeji.cn/zaolu/20211225/5528d2977b8558d930bdc0446cbc40e8.png"
          width="28"
          height="28"
          fit="cover"
        />
        <div @click="$router.push('/saleman/manager')" class="tabItemLabel">
          朋友圈
        </div>
      </div>
      <div class="tabItem">
        <van-image
          @click="$router.push('/saleman/withdraw')"
          src="https://cdn.yukongkeji.cn/zaolu/20211225/e2ec18a43820028d214c202f33180d3f.png"
          width="28"
          height="28"
          fit="cover"
        />
        <div @click="$router.push('/saleman/withdraw')" class="tabItemLabel">
          积分兑现
        </div>
      </div>
      <div class="tabItem">
        <van-image
          @click="clearCache()"
          src="https://cdn.yukongkeji.cn/zaolu/20211225/1895ceb7a28a1932d2a939b56dd23b58.png"
          width="28"
          height="28"
          fit="cover"
        />
        <div @click="clearCache()" class="tabItemLabel">清除缓存</div>
      </div>
      <div class="tabItem"></div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import {Api, SalemanApi} from "../../services/api";
import { SET_SALE_INFO_MUTATION } from "../../mutation-types";
export default {
  name: "Saleman",
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.sale;
    },
  },
  mounted() {
    this.isVip()
    setTimeout(()=>{
      this.fetchUserInfo();
    },500)
  },
  methods: {
    isVip(){
      Api.isVip().then((res) => {
        console.log(111)
        if (res.data === 0) {
          this.$router.push({
            path: "/user/vip",
          });
        }
      });
    },
    fetchUserInfo() {
      SalemanApi.getUserInfo().then((res) => {
        if (res) {
          this.$store.commit({
            type: SET_SALE_INFO_MUTATION,
            payload: res.data,
          });
        }
      });
    },
    clearCache() {
      Toast.loading("清除中...");
      setTimeout(() => {
        SalemanApi.clearCache().then(() => {
          localStorage.setItem("version", Date.now());
          Toast.success("清除成功");
          this.fetchUserInfo();
        });
      }, 2000);
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

.no-padding {
  padding: 0;
  margin: 0;
}

.padding-left {
  padding-left: 20px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  background-color: #f2f2f2;
  min-height: 100%;
  position: relative;
}

.top {
  width: 100%;
  height: 160px;
  background: linear-gradient(to right, #fd673d, #ff1e0f);
  display: flex;
  align-items: center;
  position: relative;
}

.userinfo {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-left: 20px;
  align-items: center;
}

.avatar {
  border-radius: 50%;
}

.username {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-indent: 10px;
}

.integral {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  width: 94%;
  position: absolute;
  top: 120px;
  left: 3%;
}

.integralItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 0;
  margin: 0;
  flex: 1;
}

.integralItem .key {
  color: #666;
  font-size: 14px;
  position: relative;
}

.integralItem .key .detail {
  color: #fff;
  background-color: #ff3b23;
  border-radius: 30px;
  padding: 0 2px;
  position: absolute;
  right: -35px;
  font-size: 13px;
}

.integralItem .value {
  color: #ff3b23;
  font-size: 17px;
  font-weight: 700;
}

.tab {
  width: 94%;
  height: 85px;
  background-color: #fff;
  margin-top: 50px;
  margin-left: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tabItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabItemLabel {
  font-size: 14.4px;
  font-weight: normal;
  color: #333333;
  padding-top: 3px;
}
</style>
