<template>
  <Nav title="物流信息" />
  <div
    v-if="fetchLoading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <van-loading type="circular" style="margin-top: 100px" />
  </div>
  <div id="main" class="container" v-if="express && order">
    <div class="main">
      <div class="cominfo">
        <div
          class="logo"
          style="
            background-image: url('https://cdn.yukongkeji.cn/zaolu/20211225/b4848fca45bbfaa097357eb1461fd6af.png');
            background-repeat: no-repeat;
          "
        ></div>
        <div class="text">
          <div class="name">{{ order.fast }}</div>
          <div class="select-wrap" id="content">{{ order.fast_num }}</div>
          <button
            id="copy"
            @click="copy"
            v-if="order.fast_num"
            style="color: #317ee7; margin-left: 10px"
          >
            复制
          </button>
        </div>
      </div>
      <section class="result-box">
        <div class="result-success">
          <ul class="result-list sortup pad_top_12">
            <li
              :key="index"
              v-for="(item, index) in express"
              :class="index == 0 ? 'finish' : ''"
            >
              <div class="dot"></div>
              <div class="information">
                <div class="vtime">
                  <div>{{ item.time }}</div>
                </div>
                <div class="context">{{ item.context }}</div>
              </div>
            </li>
          </ul>
          <div class="more bottom5" style="display: none">
            <span class="more-text"></span>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import Nav from "./common/Nav.vue";
import { Api } from "../services/api";
export default {
  name: "Express",
  components: {
    Nav,
  },
  data() {
    return {
      express: null,
      order: null,
      fetchLoading: true,
    };
  },
  methods: {
    // 复制文本
    copy() {
      var oInput = document.createElement("textarea"); //'textarea'
      document.body.appendChild(oInput);
      oInput.value = this.order.fast_num;
      oInput.focus();
      oInput.select();
      document.execCommand("Copy");
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      Toast.success("复制成功");
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    Api.orderExpress(this.id).then((res) => {
      this.express = res.data.express;
      this.order = res.data.order;
      this.fetchLoading = false;
    });
  },
};
</script>

<style scoped>
.container,
.main {
  position: relative;
  background: #efeff4;
  -webkit-transform-origin: 50% 50% 50%;
  transform-origin: 50% 50% 50%;
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  overflow-y: scroll;
  height: 100%;
}
.main {
  padding-top: 50px;
}
.cominfo {
  align-items: center;
  padding: 15px 10px 10px;
  border-bottom: 6px solid #f1f1f1;
  font-size: 14px;
  color: #333333;
  background: #fff;
  display: flex;
}
.cominfo .logo {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 10px;
  border-radius: 50%;
  background-size: 100%;
}
.cominfo .text {
  width: 0;
  flex: 1;
}
.cominfo .select-wrap {
  position: relative;
  display: inline-block;
}
.result-box {
  background: #fff;
}
.result-success {
  position: relative;
  padding-bottom: 0.01rem;
}
.pad_top_12 {
  padding-top: 12px;
}
.result-list li.finish {
  color: #317ee7;
}
.result-list .dot {
  background: #e87c7d;
  z-index: 8;
  margin-left: 15px;
  width: 9px;
  height: 9px;
  box-shadow: 0 0 0 2px rgb(49 126 231 / 20%);
  border-radius: 50%;
}
.information {
  width: 90%;
  background-color: #f1f1f1;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0 12px;
  border-radius: 5px;
}
.information .vtime {
  font-size: 14px;
  padding: 6px;
}
.information .context {
  color: #999999;
  font-size: 14px;
  padding: 0 6px 10px;
}
.result-success .bottom5 {
  bottom: 5.5em;
}
@-webkit-keyframes myfirst {
  from {
    bottom: 15px;
  }

  to {
    bottom: 5px;
  }
}
.result-success .more-text {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  color: #888;
  font-size: 0.875rem;
  animation: myfirst 0.7s infinite alternate;
}
.result-list .finish .dot {
  background: #e87c7d;
  z-index: 8;
  margin-left: 15px;
  box-shadow: 0 0 0 2px rgb(49 126 231 / 20%);
  width: 9px;
  height: 9px;
  -webkit-transform: translate(2px);
  transform: translate(2px);
  border-radius: 50%;
}

.result-list li.finish {
  color: #317ee7;
}

.result-list li {
  padding-bottom: 0.5rem;
  color: #999999;
  display: flex;
}

.information::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.5px;
  background-color: #ddd;
  left: 20px;
  height: 100%;
  top: 0;
  /* z-index: 8; */
}
</style>
