<template>
  <Nav title="明细" />
  <div class="mxPage">
    <div class="tabtime">
      <span style="padding-right: 5px" @click="selectDateLimit">{{
        dateLimitStr
      }}</span>
      <van-image
        @click="selectDateLimit"
        width="16"
        height="11"
        src="https://cdn.yukongkeji.cn/zaolu/20211225/45064695df28e5f63501613578f2cf37.png"
      />
      <van-image
        v-if="this.id"
        class="share"
        @click="share"
        src="https://cdn.yukongkeji.cn/zaolu/20211227/994e8ebbaf2d918a7402b6a55bde83fe.png"
        alt=""
      />
    </div>
    <div v-if="list.length > 0" class="list-all">
      <div class="list-item" v-for="item in list" :key="item.id">
        <div class="time">
          {{ formatTime(item.time) }}
        </div>
        <div class="colnum">
          <div class="amount">
            {{ item.type === 1 ? "-" : "+" }}{{ item.money }}
          </div>
          <div>{{ item.name }}({{ item.vip_code }})</div>
        </div>
      </div>
    </div>
    <div style="width: 100%;text-align: center; margin-top: 20px"></div>
  </div>
  <van-popup position="bottom" :show="show" @click-overlay="show = false">
    <van-tabs :active="active">
      <van-tab title="开始时间" style="width: 100%; height: 100%">
        <van-datetime-picker
          v-model="startDate"
          type="date"
          title=""
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onStartDateOk"
        />
      </van-tab>
      <van-tab title="结束时间" style="width: 100%; height: 100%">
        <van-datetime-picker
          v-model="endDate"
          type="date"
          title=""
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onEndDateOk"
        />
      </van-tab>
    </van-tabs>
  </van-popup>
</template>

<script>
import moment from "moment";
import { SalemanApi } from "../../services/api";
import Nav from "../common/Nav.vue";
import { computed } from "vue-demi";
import { Toast } from "vant";
import { ref } from 'vue';
export default {
  setup() {
    const startDate = ref(new Date(2021, 0, 16));
    const endDate = ref(new Date(2021, 0, 18));
    const formatTime = computed(() => {
      return (time) => moment(time).format("YYYY-MM-DD HH:mm:ss");
    });
    return {
      // minDate: new Date(2020, 0, 1),
      // maxDate: new Date(2025, 10, 1),
      startDate,
      endDate,
      formatTime
    }

    // window.scrollTo(0, 0);
  },
  components: { Nav },
  data() {
    return {
      list: [],
      // startDate: null,
      // endDate: null,
      minDate: this.startDate || new Date(2020, 0, 1),
      maxDate: new Date(),
      dateLimitStr: "近三个月",
      show: false,
      active: 0,
      isShowNotDetail:true
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    code() {
      return this.$route.query.code;
    }
  },
  mounted(option) {
    this.code = this.$route.params.id
    console.log(this.code,'this.code')
    // todo this.code = this.$route.params.id
    if (this.code) {
      SalemanApi.getBillShareData(this.code).then((res) => {
        if (res.status === 1) {
          if (res.data.time_limit.start_time) {
            this.dateLimitStr =
              moment(res.data.time_limit.start_time).format("YYYY-MM-DD") +
              "至" +
              moment(res.data.time_limit.end_time).format("YYYY-MM-DD");
            console.log(111)
          } else {
            this.dateLimitStr = "近三个月";
            console.log(222)
          }
          console.log(333)
          this.list = res.data.bill_list;
          if(this.list.length > 0){
            this.isShowNotDetail = true
          }
        } else {
          Toast(res.msg);
        }
      });
    } else {
      this.fetchData(this.id ? this.id : null, null, null);
    }
  },
  methods: {
    selectDateLimit() {
      this.show = true
      if (!this.code) {
        this.show = true;
      }
    },
    share() {
      SalemanApi.getBillShareUrl({
        start_time: this.startDate ? this.startDate.getTime() : null,
        end_time: this.endDate ? this.endDate.getTime() : null,
        id: this.id,
      }).then((res) => {
        if (res.status > 0) {
          var oInput = document.createElement("input");
          oInput.value = res.data;
          document.body.appendChild(oInput);
          oInput.select();
          document.execCommand("Copy");
          document.body.removeChild(oInput);
          Toast.success("复制分享链接成功！");
        } else {
          Toast.fail(res.msg);
        }
      });
    },
    onStartDateOk() {
      this.active = 1;
      console.log(this.startDate);
      this.minDate = this.startDate;
    },
    onEndDateOk() {
      if (this.startDate.getTime() > this.endDate.getTime()) {
        Toast.fail("开始日期不能大于结束日期");
        return;
      }
      this.active = 0;
      this.show = false;
      this.fetchData(
        this.id ? this.id : null,
        this.startDate.getTime(),
        this.endDate.getTime()
      );
    },
    async fetchData(id, start, end) {
      this.list = [];
      const res = await SalemanApi.getBillList(id, start, end);
      if (res.status === 1) {
        this.list = res.data.bill_list;
        this.minDate = moment(
          res.data.time_limit.start_time,
          "YYYY-MM-DD"
        ).toDate();
        this.startMinDate = moment(
          res.data.time_limit.start_time,
          "YYYY-MM-DD"
        ).toDate();
        this.startEndDate = moment(
          res.data.time_limit.end_time,
          "YYYY-MM-DD"
        ).toDate();
        this.maxDate = moment(
          res.data.time_limit.end_time,
          "YYYY-MM-DD"
        ).toDate();

        if (start && end) {
          this.dateLimitStr =
            moment(start).format("YYYY-MM-DD") +
            "至" +
            moment(end).format("YYYY-MM-DD");
        }
        console.log(this.dateLimitStr)
      } else {
        Toast.fail(res.msg);
      }
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

.list-item {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  border-radius: 3px;
  justify-content: space-between;
}

.time {
  font-size: 14px;
  color: #666666;
}

.colnum {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.share {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 23px !important;
  height: 23px !important;
}

.num1 {
  color: #333333;
  text-align: right;
  font-weight: bold;
  font-size: 16px;
}

.zrname {
  font-size: 14px;
  color: #333333;
  text-align: right;
}

.list-all {
  height: auto;
  width: 97%;
  margin: 0 auto;
}

.mxPage {
  padding: 0;
  height: 0;
  background-color: #f2f2f2;
  min-height: 100%;
  margin-top: 50px;
  overflow-y: scroll;
}

.extra {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 14px;
  color: #666666;
}

.item {
  font-size: 14px;
  color: #666666;
}

.amount {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.tabtime {
  padding: 5px 10px;
  background-color: #fff;
  color: #333333;
  font-size: 17px;
  font-weight: bold;
  margin-top: 2px;
  position: relative;
}
</style>
