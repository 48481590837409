<template>
  <div id="lg" class="lg" v-cloak>
    <div
      style="width: 100%; height: 100%; display: flex; flex-direction: column"
    >
      <div style="width: 100%; position: relative">
        <img
          src="https://assets.yukongkeji.cn/zaolu/20220831/857C6358EBB593279B21A3B11D2B22E1.png"
          alt="vip-top"
          style="width: 100%; height: auto; vertical-align: bottom"
        />
        <div class="pick" v-show="vip">您已是会员，无需再次开通！</div>
      </div>
      <div
        v-show="vip"
        style="
          font-size: 18px;
          width: 100%;
          flex: 1;
          min-height: 65px;
          background: #efeeef;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <div
          @click="go"
          style="
            margin-top: 20px;
            padding: 0 10px;
            letter-spacing: 2px;
            text-align: center;
            background: linear-gradient(#d79b35, #884721);
            line-height: 30px;
            width: 120px;
            height: 30px;
            border-radius: 10px;
            color: #fff;
          "
        >
          点击进入
        </div>
      </div>
      <div
        v-show="vip === false"
        style="
          font-size: 18px;
          width: 100%;
          flex: 1;
          min-height: 65px;
          background: #efeeef;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <div
          @click="showc = true"
          style="
            margin-top: 3px;
            padding: 0 10px;
            letter-spacing: 2px;
            text-align: center;
            background: linear-gradient(#d79b35, #884721);
            line-height: 30px;
            width: 120px;
            height: 30px;
            border-radius: 10px;
            color: #fff;
          "
        >
          会员通道
        </div>
        <div
          style="font-size: 16px; letter-spacing: 2px; margin-top: 10px"
          @click="callpay"
          id="pay"
        >
          会员开通>>
        </div>
      </div>
      <div>
        <img
          src="https://cdn.yukongkeji.cn/zaolu/20211225/bb1d123791df0f9eac9751f62b7ac3ba.png"
          alt="vip-bottom"
          style="width: 100%; height: auto; vertical-align: bottom"
        />
      </div>
    </div>
    <van-popup
      v-model:show="showc"
      style="
        width: 90%;
        margin: 0 auto;
        text-align: center;
        border-radius: 10px;
        background-color: #cdb48b;
        padding-top: 30px;
      "
    >
      <img
        src="https://cdn.yukongkeji.cn/zaolu/20211225/245ec61af23132874bab0f8580b7976a.png"
        alt=""
      />
      <van-form @submit="onSubmit" class="login">
        <van-field
          v-model="code"
          type="password"
          name="code"
          label="会员号"
          placeholder="请输入会员号"
          :rules="[{ required: true, message: '您的会员号尚未输入' }]"
        ></van-field>
        <div class="tip">
          <span>还不是会员？</span
          ><span
            @click="bindshow"
            style="color: rgb(252, 114, 9); text-decoration-line: underline"
            >我要入会</span
          >
        </div>
        <van-button round size="small" block native-type="submit"
          >绑定会员</van-button
        >
      </van-form>
      <div class="tip" style="margin: 0 10px 50px; text-align: center">
        <span>*会员认证通过后，即可自动登录</span>
      </div>
    </van-popup>

    <!-- 入会支付 -->
    <van-popup v-model="show" class="content">
      <div class="cont">
        <div class="money">￥{$money}</div>
        <van-button
          round
          size="small"
          block
          type="danger"
          @click="callpay"
          id="pay"
          >确认支付</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Api } from "../services/api";
export default {
  name: "Vip",
  data() {
    return {
      vip: null,
      code: null,
      show: false,
      showc: false,
      height: 0,
      imgsrc:
        "https://cdn.yukongkeji.cn/zaolu/20211225/5028cffc5207cd6f5672e595feb91219.jpg",
    };
  },
  mounted() {
    console.log(this.$route.query.redirect);
    this.height = (window.innerWidth / (2553 / 2)) * 750 - 300 / 2;
    Api.isVip().then((res) => {
      if (res.data === 1) {
        this.vip = true;
      } else {
        this.vip = false;
      }
    });
  },
  methods: {
    bindshow() {
      this.show = true;
    },
    go() {
      const redirect = this.$route.query.redirect;
      console.log(redirect);
      Api.vipPing().then(() => {
        if (redirect) {
          if (/https?:\/\//.test(redirect)) {
            const url = redirect.replace(/https?:\/\/[^/]+/, "");
            console.log(url);
            this.$router.replace(url);
          } else {
            this.$router.replace(redirect);
          }
        } else {
          this.$router.replace("/");
        }
      });
    },
    callpay() {
      console.log("这是吊起支付");
      if (!window.WeixinJSBridge) {
        Toast.fail("请在微信中打开！");
        return;
      }
      this.jsApiCall();
    },
    jsApiCall() {
      Toast.loading({
        type: "loading",
        forbidClick: true,
        duration: 0,
        message: "加载中...",
      });
      Api.vipPayConfig().then((res) => {
        if (res.status < 1) {
          Toast.fail(res.msg);
          return;
        }
        Toast.clear();
        window.WeixinJSBridge &&
          window.WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            res.data,
            (res) => {
              console.log(res)
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                Toast.success("开通成功！");
                location.reload();
              } else {
                Toast.fail("开通失败！");
              }
            }
          );
      });
    },
    onSubmit() {
      if (!this.code || this.code.length < 5) {
        Toast.fail("会员码不正确！");
        return;
      }
      Toast.loading({
        type: "loading",
        forbidClick: true,
        duration: 0,
        message: "绑定中...",
      });
      Api.bindVipCode(this.code).then((res) => {
        if (res.status === 0) {
          Toast.fail(res.msg);
          return;
        }
        Toast.success("绑定成功！");
        this.vip = true;
        this.showc = false;
        console.log(this.$router);
        const redirect = this.$route.query.redirect;
        Api.vipPing().then(() => {
          if (redirect) {
            this.$router.replace(redirect);
          } else {
            this.$router.replace("/");
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.img {
  width: 100%;
  top: 0;
  height: auto;
  position: relative;
}

.header {
  width: 100%;
  line-height: 44px;
  background-color: #fff;
  text-align: center;
  font-size: 15px;
  color: #333333;
  border-bottom: 1px solid #ededed;
}

.login {
  width: 90%;
  margin: 20px auto 0;
  z-index: 999;
}

.tobut {
  position: absolute;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  height: 70px;
  line-height: 70px;
}

.butclick {
  width: 72%;
}

.tipclick {
  font-size: 14px;
  color: #af7c43;
  text-align: end;
  padding-left: 10px;
  text-decoration-line: underline;
}

.tip {
  font-size: 13px;
  color: #777777;
  text-align: right;
  margin: 15px 10px;
}

.login button {
  width: 50%;
  margin: 20px auto;
  font-size: 15px;
}

.content {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  background-color: #cdb48b;
  padding-top: 30px;
}

.content button {
  width: 50%;
  margin: 30px auto 20px;
  font-size: 15px;
}

.cont {
  margin: 40px auto;
}

.money {
  font-weight: bold;
  font-size: 25px;
  color: #333333;
}

.lg {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.van-button {
  background-color: #1c1a1b;
  border: none;
  color: #fff;
}

.van-field__label {
  width: auto;
}

[v-cloak] {
  display: none;
}

.pick {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 16px;
  text-align: center;
  color: #333333;
}
</style>
