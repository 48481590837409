<template>
  <div
    style="
      position: fixed;
      display: flex;
      flex-direction: column;
      width: 100%;
      top: 0;
      z-index: 10;
    "
  >
    <Nav
      :title="
        status == '1'
          ? '待付款'
          : status == '2'
          ? '待发货'
          : status == '3'
          ? '待收货'
          : status == '4'
          ? '已完成'
          : status == '5'
          ? '退款'
          : status == '6'
          ? '已退款'
          : '未知状态'
      "
    />
    <div class="main">
      <a
        @click="$router.replace('/user/orders/1')"
        :class="`icon-item${status == '1' ? ' active' : ''}`"
        >待付款</a
      >
      <a
        @click="$router.replace('/user/orders/2')"
        :class="`icon-item${status == '2' ? ' active' : ''}`"
        >待发货</a
      >
      <a
        @click="$router.replace('/user/orders/3')"
        :class="`icon-item${status == '3' ? ' active' : ''}`"
        >待收货</a
      >
      <a
        @click="$router.replace('/user/orders/4')"
        :class="`icon-item${status == '4' ? ' active' : ''}`"
        >已完成</a
      >
      <a
        @click="$router.replace('/user/orders/5')"
        :class="`icon-item${status == '5' ? ' active' : ''}`"
        >退款</a
      >
    </div>
  </div>
  <div
    v-if="fetchLoading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <van-loading type="circular" style="margin-top: 100px" />
  </div>
  <van-pull-refresh
    v-model="loading"
    @refresh="onRefresh"
    style="height: 100%; overflow: scroll; padding-top: 85px"
  >
    <div style="padding-bottom: 80px">
      <div class="row" v-for="order in orders" :key="order.id">
        <div class="row-title" @click="$router.push(`/orders/${order.id}`)">
          <span>订单号:{{ order.code }}</span>
          <img
            src="https://cdn.yukongkeji.cn/zaolu/20211225/5e575b3274e1a41e584b6d492e07b43a.png"
            alt=""
          />
        </div>
        <div class="row-main" @click="$router.push(`/orders/${order.id}`)">
          <div class="main-left-image">
            <a href="">
              <img :src="order.image" alt="" />
            </a>
          </div>
          <div class="main-center-message">
            <div class="row-title-title">{{ order.name }}</div>
            <div class="text-row">
              <span>￥{{ order.price }}</span>
              <span>x{{ order.num }}</span>
            </div>
            <span class="right-price"
              >实付：<span style="color: #f2504b"
                >￥{{ order.zprice }}</span
              ></span
            >
          </div>
        </div>
        <div class="abtn" v-if="status == '1'">
          <a @click="cancelOrder(order.id)" class="right-btn-row"
            ><button class="right-btn">取消订单</button></a
          >
          <a @click="$router.push(`/pay/${order.id}`)" class="right-btn-row"
            ><button class="right-btn important">立即付款</button></a
          >
        </div>
        <div class="abtn" v-if="status == '2'">
          <a
            @click="$router.push(`/refund-confirm/${order.id}`)"
            class="right-btn-row"
            ><button class="right-btn">申请售后</button></a
          >
        </div>
        <div class="abtn" v-if="status == '3'">
          <a
            @click="$router.push(`/order/express/${order.id}`)"
            class="right-btn-row"
            ><button class="right-btn">查看物流</button></a
          >
          <a
            @click="$router.push(`/refund-confirm/${order.id}?logistics=1`)"
            class="right-btn-row"
            ><button class="right-btn">申请退货</button></a
          >

          <a @click="recieveConfirm(order.id)" class="right-btn-row"
            ><button class="right-btn important">确认收货</button></a
          >
        </div>
        <div class="abtn" v-if="status == '5'">
          <a
            @click="$router.push(`/refund-detail/${order.id}`)"
            class="right-btn-row"
            ><button class="right-btn">退款详情</button></a
          >
        </div>
      </div>

      <div
        class="icon-image"
        v-show="orders && orders.length === 0 && !fetchLoading"
      >
        <img
          src="https://cdn.yukongkeji.cn/zaolu/20211225/ab66d98d61dbcc0496588c7d08a27a0e.png"
          alt=""
        />
        <div class="icon-message">您还没有相关的订单</div>
      </div>
    </div>
  </van-pull-refresh>
</template>

<script>
import { Dialog, Toast } from "vant";
import { Api } from "../services/api";
import { SET_USER_ORDER_LIST_MUTATION } from "../mutation-types";
import Nav from "./common/Nav.vue";
export default {
  name: "OrderList",
  components: {
    Nav,
  },
  data() {
    return {
      loading: false,
      fetchLoading: false,
    };
  },
  methods: {
    onRefresh() {
      this.loading = true;
      this.getOrders();
    },
    getOrders() {
      Api.getOrderList(this.status).then((res) => {
        this.loading = false;
        this.fetchLoading = false;
        if (res.status === 1) {
          this.$store.commit({
            type: SET_USER_ORDER_LIST_MUTATION,
            payload: {
              status: this.status,
              data: res.data,
            },
          });
        }
      });
    },
    cancelOrder(id) {
      Dialog.confirm({
        message: "确定要取消该订单吗？",
      })
        .then(() => {
          Toast.loading({
            message: "加载中...",
          });
          Api.cancelOrder(id).then((res) => {
            if (res.status === 1) {
              this.getOrders();
              Toast.success("取消成功");
            } else {
              Toast.fail(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    recieveConfirm(id) {
      Dialog.confirm({
        message: "确定已经收到货？",
      }).then(() => {
        Toast.loading({
          message: "加载中...",
        });
        Api.orderRecieveConfirm(id).then((res) => {
          if (res.status === 1) {
            this.getOrders();
            Toast.success("确认成功");
          } else {
            Toast.fail(res.msg);
          }
        });
      });
    },
  },
  mounted() {
    this.fetchLoading = true;
    this.getOrders();
  },
  computed: {
    status() {
      return ~~this.$route.params.status;
    },
    orders() {
      if (!this.$store.state.userOrderList) {
        return null;
      }
      return this.$store.state.userOrderList[`status_${this.status}`];
    },
  },
  watch: {
    status() {
      if (!this.orders) {
        this.fetchLoading = true;
        this.getOrders();
      }
    },
  },
};
</script>

<style scoped>
.main {
  display: flex;
  background-color: #fff;
  padding-top: 50px;
}

.icon-image {
  margin: 65px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
}

.icon-image img {
  width: 100px;
  height: 100px;
}

.icon-message {
  text-align: center;
  font-size: 14px;
  color: #707070;
}

.icon-item {
  flex: 1;
  font-size: 15px;
  text-align: center;
  border-right: 1px solid #efefef;
  line-height: 40px;
  height: 40px;
  color: #000;
}

.active {
  background-color: #ff6b0a;
  color: #fff;
}

.row {
  width: 94%;
  height: auto;
  padding: 5px 0 10px;
  background-color: #fff;
  margin: 10px auto;
  border-radius: 5px;
}

.row-title {
  width: 94%;
  height: 30px;
  line-height: 30px;
  margin: 0 auto 10px;
  font-weight: 600;
  font-size: 15px;
  position: relative;
  border-bottom: 1px solid #efefef;
}

.row-title span {
  display: inline-block;

  margin-left: 5px;
  margin-bottom: 15px;
}

.row-title img {
  position: absolute;
  right: -2px;
  top: 7px;
  width: 18px;
  height: 16px;
}

.row-main {
  display: flex;
  height: auto;
  background-color: #fff;
  border-radius: 16px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 10px;
  position: relative;
}

.main-left-image {
  flex: 2;
  margin-left: 15px;
  width: 80px;
  height: 80px;
}

.main-left-image img {
  width: 100%;
  height: 100%;
}

.main-center-message {
  flex: 5;
  margin-left: 15px;
  height: auto;
}

.row-title-title {
  font-size: 15px;
}

.text-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  color: #666666;
  padding-right: 15px;
  padding-top: 5px;
}

.right-price {
  float: right;
  font-size: 14px;
  right: 15px;
  bottom: 10px;
  position: absolute;
}

.right-price span {
  color: #f2504b;
}
.abtn {
  display: flex;
  justify-content: flex-end;
}

.right-btn-row {
  display: block;
  text-align: right;
}

.right-btn {
  border-width: 0px;
  border-radius: 20px;
  background: #fff;
  cursor: pointer;
  outline: none;
  color: #f56f39;
  font-size: 14px;
  text-align: right;
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  text-align: center;
  border: 1px solid #f56f39;
}

.right-btn-row {
  display: block;
  text-align: right;
}

.right-btn {
  border-width: 0px;
  border-radius: 20px;
  background: #fff;
  cursor: pointer;
  outline: none;
  color: #f56f39;
  font-size: 14px;
  text-align: right;
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  text-align: center;
  border: 1px solid #f56f39;
}

.important {
  background-color: #f56f39;
  color: #fff;
}
</style>
