import { createStore } from "vuex";
import {
  SET_PRODUCT_MUTATION,
  SET_CURRENT_WARE_SPEC_MUTATION,
  SET_USER_INFO_MUTATION,
  SET_USER_ADDRESS_LIST_MUTATION,
  CHANGE_USER_ADDRESS_DEFAULT_MUTATION,
  SET_USER_ORDER_LIST_MUTATION,
  ADD_BUY_COUNT_MUTATION,
  SUB_BUY_COUNT_MUTATION,
  SET_SALE_INFO_MUTATION,
  SET_MANAGER_PAGE_POSITION,
  CLEAR_USER_ORDER_LIST,
} from "./mutation-types";

const store = createStore({
  state: {
    product: null,
    //currentWareSpec: localStorage.getItem("currentWareSpec")
    //  ? JSON.parse(localStorage.getItem("currentWareSpec"))
    //  : null,
    currentWareSpec: null,
    user: null,
    userOrderList: {},
    buyCount: localStorage.getItem("buyCount") || 1,
    sale: {},
    managerPagePosition: 0,
  },
  mutations: {
    [SET_PRODUCT_MUTATION](state, action) {
      state.product = action.payload;
    },
    [SET_CURRENT_WARE_SPEC_MUTATION](state, action) {
      state.currentWareSpec = action.payload;
      localStorage.setItem("currentWareSpec", JSON.stringify(action.payload));
    },
    [SET_USER_INFO_MUTATION](state, action) {
      state.user = action.payload;
    },
    [SET_USER_ADDRESS_LIST_MUTATION](state, action) {
      state.userAddressList = action.payload;
    },
    [CHANGE_USER_ADDRESS_DEFAULT_MUTATION](state, action) {
      if (state.userAddressList) {
        state.userAddressList.forEach((item) => {
          item.status = item.id === action.payload ? 1 : 0;
        });
        // 重新排序
        state.userAddressList.sort((u1, u2) => {
          return u2.status === 1 || (u1.status === 0 && u2.id > u1.id) ? 1 : -1;
        });
      }
    },
    [SET_USER_ORDER_LIST_MUTATION](state, action) {
      state.userOrderList[`status_${action.payload.status}`] =
        action.payload.data;
    },
    [ADD_BUY_COUNT_MUTATION](state) {
      state.buyCount++;
      localStorage.setItem("buyCount", state.buyCount);
    },
    [SUB_BUY_COUNT_MUTATION](state) {
      state.buyCount--;
      localStorage.setItem("buyCount", state.buyCount);
    },
    [SET_SALE_INFO_MUTATION](state, action) {
      state.sale = action.payload;
    },
    [SET_MANAGER_PAGE_POSITION](state, action) {
      console.log(action);
      state.managerPagePosition = action.payload;
    },
    [CLEAR_USER_ORDER_LIST](state) {
      state.userOrderList = {};
    },
  },
});

export default store;
