<template>
  <Nav title="确认支付" />
  <div
    v-if="fetchLoading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <van-loading type="circular" style="margin-top: 100px" />
  </div>
  <div class="centent" v-if="order">
    <div class="title">{{ order.name }}</div>
    <div class="money">￥{{ order.zprice }}</div>
    <div class="btn" @click="callpay()">立即支付</div>
  </div>
</template>

<script>
import { Toast } from "vant";
import Nav from "./common/Nav.vue";
import { Api } from "../services/api";

export default {
  components: {
    Nav,
  },
  data() {
    return {
      money: 111,
      name: "早露粉",
      order: null,
      fetchLoading: true,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    Api.orderDetail(this.id).then((res) => {
      this.fetchLoading = false;
      res.status === 1 && (this.order = res.data);
    });
  },
  methods: {
    callpay() {
      Toast.loading({ message: "支付中...", duration: 0 });
      if (window.WeixinJSBridge === undefined) {
        Toast.fail("请在微信中打开");
        return;
      }
      Api.getOrderPay(this.id).then((res) => {
        if (res.status === 1) {
          window.WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            res.data,
            (res) => {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                Toast.success("支付成功！");
                this.$router.replace("/user/orders/2");
              } else {
                Toast.fail("支付失败！");
                this.$router.replace("/user/orders/1");
              }
            }
          );
        } else {
          Toast.fail("操作失败！");
        }
      });
    },
  },
};
</script>

<style scoped>
.centent {
  padding: 10px 15px;
  padding-top: 50px;
}

.title {
  width: 100%;
  font-size: 16px;
  line-height: 2;
  text-align: center;
}

.money {
  width: 100%;
  font-size: 32px;
  text-align: center;
  line-height: 2;
}

.btn {
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  background-color: #07bf05;
  border-radius: 5px;
  color: #fff;
}
</style>
