import Router from "../route";

async function request(url, options) {
  try {
    options = options || {};
    if (options && (options.method === "POST" || options.method === "PUT")) {
      options = {
        ...options,
        headers: {
          "Content-Type": options.headers ? options.headers : "application/json",
        },
        body: options.data ? JSON.stringify(options.data) : null,
      };
    }
    if (options.params) {
      const params = Object.keys(options.params)
        .map((key) => `${key}=${encodeURIComponent(options.params[key])}`)
        .join("&");
      url = url.indexOf("?") > -1 ? `${url}&${params}` : `${url}?${params}`;
    }
    const response = await fetch(url, options);
    if (response.status === 499) {
      return Promise.resolve();
    }
    if (response.status === 200) {
      const data = await response.json();
      if (data.status === 302) {
        if (data.sync) {
          location.href = data.data;
        } else {
          Router.replace(data.data);
        }
        return;
      }
      return Promise.resolve(data);
    } else {
      // Toast.fail("网络错误！" + response.status);
      return Promise.resolve();
    }
  } catch (error) {
    // Toast.fail(error.message);
    return Promise.resolve({
      status: 0,
      msg: "网络错误!" + error.message,
    });
  }
}

async function getUserInfo() {
  return request("/api/saleman/info");
}

async function clearCache() {
  return request("/api/clearCache");
}

async function withdraw() {
  return request("/api/saleman/withdraw", {
    method: "POST",
  });
}

async function getBillList(id, startDate, endDate) {
  const params = {};
  id && (params.id = id);
  startDate && (params.startDate = startDate);
  endDate && (params.endDate = endDate);
  return request(`/api/saleman/money/bill-list`, {
    params,
  });
}

async function getHospitalList() {
  return request("/api/saleman/hospitals");
}

async function addHospital(data) {
  return request("/api/saleman/hospitals", {
    method: "POST",
    data,
  });
}

async function addDepartment(hostId, data) {
  return request(`/api/saleman/hospitals/${hostId}/departments`, {
    method: "POST",
    data,
  });
}

async function addDoctor(depId, data) {
  return request(`/api/saleman/hospitals/departments/${depId}/doctors`, {
    method: "POST",
    data,
  });
}

async function getDepartmentList(hosId) {
  return request("/api/saleman/hospitals/" + hosId + "/departments");
}

async function getDoctors(departmentId) {
  return request(
    "/api/saleman/hospitals/departments/" + departmentId + "/doctors"
  );
}

async function saveHospital(id, data) {
  return request(`/api/saleman/hospitals/${id}`, {
    method: "PUT",
    data,
  });
}

async function saveDepartment(id, data) {
  return request(`/api/saleman/departments/${id}`, {
    method: "PUT",
    data,
  });
}

async function saveDoctor(id, data) {
  return request(`/api/saleman/hospitals/departments/doctors/${id}`, {
    method: "PUT",
    data,
  });
}

async function writeOff(data) {
  return request("/api/saleman/doctors/write-off", {
    method: "POST",
    data,
  });
}

async function getBillShareUrl(data) {
  return request("/api/saleman/money/bill-share", {
    method: "GET",
    params: data,
  });
}

async function getBillShareData(code) {
  return request("/api/saleman/money/bill-share-list", {
    params: {
      code,
    },
  });
}

async function getDoctorBillList(params) {
  return request("/api/saleman/money/doctor-bill-list", {
    params,
  });
}

export const SalemanApi = {
  getUserInfo,
  clearCache,
  withdraw,
  getBillList,
  getHospitalList,
  addHospital,
  addDepartment,
  addDoctor,
  getDepartmentList,
  getDoctors,
  saveHospital,
  saveDepartment,
  saveDoctor,
  writeOff,
  getBillShareUrl,
  getBillShareData,
  getDoctorBillList,
  uploadImg
};

async function cancelOrder(id) {
  return request(`/api/user/order/${id}`, {
    method: "DELETE",
  });
}

async function refund(data) {
  return request(`/api/user/order/refund`, {
    method: "POST",
    data: data,
  });
}

async function orderDetail(id) {
  return request(`/api/user/orderdetail?id=${id}`);
}

async function orderExpress(id) {
  return request(`/api/user/order/${id}/express`);
}

async function orderRecieveConfirm(id) {
  return request(`/api/user/order/${id}/recieve`, {
    method: "POST",
  });
}

async function bindVipCode(code) {
  return request("/api/users/bind-vip", {
    method: "POST",
    data: {
      code,
    },
  });
}

async function vipPing() {
  return request("/api/user/vipPing");
}

async function vipPayConfig() {
  return request("/api/vip-pay");
}

async function getProductDetail() {
  return request("/api/product/detail");
}

async function submitOrder(data) {
  return request("/api/user/orders", {
    method: "POST",
    data,
  });
}

async function getOrderPay(id) {
  return request(`/api/user/orders/${id}/pay`);
}

async function isVip() {
  return request("/api/user/isVip");
}

async function getUserCenter() {
  return request("/api/user/center");
}

async function getArticle(id) {
  return request(`/api/article/${id}`);
}

async function getAddressList() {
  return request("/api/user/addresses");
}

async function getOrderDetail(id) {
  return request(`/api/user/orderdetail?id=${id}`);
}

async function calcOrderPrice(specId, num) {
  return request("/api/user/orders/calc", {
    params: {
      specId: specId,
      num: num,
    },
  });
}

async function getOrderList(status) {
  return request(`/api/user/orders?status=${status}`);
}

async function deleteAddress(id) {
  return request(`/api/user/address/${id}`, { method: "DELETE" });
}

async function setDefaultAddress(id) {
  return request(`/api/addresses/${id}/default`, { method: "PUT" });
}

async function addAddress(data) {
  return request(`/api/addresses`, {
    method: "POST",
    data,
  });
}

async function saveAddress(id, data) {
  return request(`/api/user/address/${id}`, {
    method: "PUT",
    data,
  });
}

async function getAddressDetail(id) {
  return request(`/api/user/address/${id}`);
}

async function uploadImg(data) {
  return request(`/api/upload`,{
    method: "POST",
    data,
    headers: 'multipart/form-data'
  });
}

export const Api = {
  cancelOrder,
  refund,
  orderDetail,
  orderExpress,
  orderRecieveConfirm,
  bindVipCode,
  vipPing,
  vipPayConfig,
  getProductDetail,
  submitOrder,
  getOrderPay,
  isVip,
  getUserCenter,
  clearCache,
  getArticle,
  getAddressList,
  getOrderDetail,
  getOrderList,
  deleteAddress,
  setDefaultAddress,
  addAddress,
  saveAddress,
  getAddressDetail,
  calcOrderPrice,
};
