<template>
  <Nav title="确认订单" />
  <div style="padding-top: 50px">
    <div class="address" v-if="!addressList"></div>
    <div
      style="padding: 20px 5px 20px 10px"
      class="address"
      @click="$router.push('/user/address')"
      v-if="addressList && addressList.length === 0"
    >
      <a class="flex_row p16">
        <img
          src="https://cdn.yukongkeji.cn/zaolu/20211225/29fc904958228b74302802f61e7d138c.png"
          style="width: 20px; height: auto"
          alt=""
        />
        <div class="flex_1 p_lt_6 font">请添加收货地址</div>
        <img
          src="https://cdn.yukongkeji.cn/zaolu/20211225/5e575b3274e1a41e584b6d492e07b43a.png"
          alt=""
          style="width: 15px; height: auto"
        />
      </a>
    </div>
    <div class="address" v-if="addressList && addressList.length > 0">
      <a @click="$router.push('/user/address?select=1')" class="flex_row p_12">
        <img
          src="https://cdn.yukongkeji.cn/zaolu/20211225/29fc904958228b74302802f61e7d138c.png"
          style="width: 20px; height: auto"
          alt=""
        />
        <div class="flex_1 p_lr_6">
          <div class="flex_between">
            <span class="userinfo">收货人：{{ addressList[0].username }}</span>
            <span class="userinfo">电话：{{ addressList[0].phone }}</span>
          </div>
          <div class="address-item">地址:{{ addressList[0].addr }}</div>
        </div>
        <img
          src="https://cdn.yukongkeji.cn/zaolu/20211225/5e575b3274e1a41e584b6d492e07b43a.png"
          alt=""
          style="width: 15px; height: auto"
        />
      </a>
    </div>

    <!--    商品info    -->

    <div class="info" v-if="currentSpec">
      <div class="info-img">
        <img :src="`${currentSpec.simage}`" alt="" />
      </div>
      <div class="right-row">
        <div class="right-title">早露粉</div>
        <div class="right-detail">{{ currentSpec.sname }}</div>
        <div class="right-size">x{{ buyCount }}</div>
        <div class="right-price">
          合计：<span style="font-weight: bold; color: #d8585e"
            >￥{{ totalPrice }}</span
          >
        </div>
      </div>
    </div>

    <div class="submit" v-if="currentSpec">
      <div class="submit-left">
        <div>
          共：<span style="font-weight: bold; color: #d8585e"
            >{{ buyCount }}件</span
          >
        </div>
        <div>
          应付：<span style="font-weight: bold; color: #d8585e"
            >￥{{ totalPrice }}</span
          >
        </div>
      </div>
      <div class="submit-right">
        <button id="buy" @click="submitOrder">提交订单</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { SET_USER_ADDRESS_LIST_MUTATION } from "../mutation-types";
import Nav from "./common/Nav.vue";
import { Api } from "../services/api";

export default {
  name: "OrderConfirm",
  data() {
    return {
      spec: null,
      totalPrice: "-",
    };
  },
  components: {
    Nav,
  },
  methods: {
    submitOrder() {
      console.log("submit order");
      Toast.loading({
        duration: 0,
        message: "提交中...",
        forbidClick: true,
      });
      if (!this.currentSpec || !this.buyCount) {
        this.$router.replace("/");
        return;
      }
      if (!this.addressList || this.addressList.length === 0) {
        Toast.fail("请选择收货地址！");
        return;
      }
      Api.submitOrder({
        aid: this.addressList[0].id,
        gid: this.currentSpec.gid,
        sid: this.currentSpec.id,
        num: this.buyCount,
      }).then((res) => {
        if (res.status > 0) {
          Toast.clear();
          this.$router.push(`/pay/${res.data.id}`);
        } else {
          Toast.fail("提交订单失败!");
        }
      });
    },
  },
  mounted() {
    if (!this.currentSpec) {
      this.$router.replace("/");
      return;
    }
    if (!this.addressList) {
      Api.getAddressList().then((res) => {
        this.$store.commit({
          type: SET_USER_ADDRESS_LIST_MUTATION,
          payload: res.data,
        });
      });
    }
    Api.calcOrderPrice(
      this.currentSpec ? this.currentSpec.id : "",
      this.buyCount ? this.buyCount : 1
    ).then((res) => {
      console.log(res);
      if (res.status > 0) {
        this.totalPrice = res.data;
      }
    });
  },
  computed: {
    addressList() {
      return this.$store.state.userAddressList;
    },
    currentSpec() {
      return this.$store.state.currentWareSpec;
    },
    buyCount() {
      return this.$store.state.buyCount;
    },
  },
};
</script>

<style scoped>
.address-item {
  color: #666666;
  font-size: 13px;
}
.p_12 {
  padding: 12px;
}

.p_lr_6 {
  padding-left: 6px;
  padding-right: 6px;
}

.flex_1 {
  flex: 1;
  overflow: hidden;
}

.userinfo {
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.address {
  width: 95%;
  margin: 10px auto;
  border-radius: 10px;
  background-color: #ffffff;
}

.info {
  display: flex;
  padding: 15px 12px;
  background-color: #fff;
}

.info-img {
  flex: 3;
  width: 90px;
  border-radius: 5px;
  height: 90px;
}

.info-img img {
  width: 100%;
  height: 100%;
}
.right-row {
  flex: 7;
  padding-left: 7px;
}

.right-title {
  color: #333333;
  font-weight: bold;
  font-size: 16px;
}

.right-detail {
  color: #666666;
  font-size: 14px;
  padding-top: 5px;
}

.right-size {
  text-align: right;
  font-size: 14px;
  color: #666666;
}

.right-price {
  text-align: right;
  font-size: 15px;
  color: #333333;
  padding-top: 10px;
}

.submit {
  width: 100%;
  height: 55px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  z-index: 99;
}

.submit-left {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-size: 15px;
  color: #333333;
  padding: 10px;
  position: absolute;
  right: 110px;
}

.submit-right button {
  position: absolute;
  right: 0;
  width: 110px;
  height: 100%;
  border: none;
  background: #d8585e;
  cursor: pointer;
  outline: none;
  color: white;
  font-size: 16px;
  float: right;
}
</style>
