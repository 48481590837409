<template>
  <router-view v-slot="{ Component }">
    <keep-alive include="Product,OrderList,SaleManager">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      currentUrl: null,
      currentPosition: null,
    };
  },
  mounted() {
    window.addEventListener(
      "popstate",
      (event) => {
        if (
          (["/", "/user", "/saleman", "/user/vip"].indexOf(this.currentUrl) >
            -1 &&
            this.currentPosition > event.state.position) ||
          (this.currentPosition === null &&
            ["/", "/user", "/saleman", "/user/vip"].indexOf(
              event.state.forward
            ) > -1)
        ) {
          window.WeixinJSBridge && window.WeixinJSBridge.call("closeWindow");
        } else {
          this.currentPosition = event.state.position;
          this.currentUrl = event.state.current;
        }
      },
      true
    );
  },
};
</script>

<style>
/*包含以下四种的链接*/
a {
  text-decoration: none;
}
/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}
/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}
/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}
/* 正在点击的链接*/
a:active {
  text-decoration: none;
}
* {
  vertical-align: baseline;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  /*font-size: 100%;*/
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
html,
body {
  height: 100%;
  background-color: #ededed;
  overflow-y: hidden;
}

:link,
:visited {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

/*h1,*/
/*h2,*/
/*h3,*/
/*h4,*/
/*h5,*/
/*h6,*/
/*pre,*/
/*code {*/
/*  font-size: 1em;*/
/*}*/

ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
body,
html,
p,
blockquote,
fieldset,
input {
  margin: 0;
  padding: 0;
}

a img,
:link img,
:visited img {
  border: none;
}

address {
  font-style: normal;
}
</style>
