<template>
  <Nav title="积分兑现" />

  <div class="integralTitle">可兑积分：{{ user.rel_money }}</div>
  <div style="text-align: center">
    <van-button
      :loading="proLoading"
      class="button"
      type="primary"
      @click="onWithdraw"
    >
      立即兑现
    </van-button>
  </div>
</template>

<script>
import { Toast } from "vant";
import { SalemanApi } from "../../services/api";
import Nav from "../common/Nav.vue";

export default {
  components: { Nav },
  data() {
    return {
      money: 0,
      proLoading: false,
    };
  },
  mounted() {
    if (!this.$store.state.sale.id) {
      SalemanApi.getUserInfo().then((res) => {
        this.$store.commit({
          type: "SET_SALE_INFO_MUTATION",
          payload: res.data,
        });
        this.money = res.data.money;
      });
    }
  },
  methods: {
    onWithdraw() {
      this.proLoading = true;
      SalemanApi.withdraw().then((res) => {
        this.proLoading = false;
        if (res === 0) {
          Toast.success("提现成功");
        } else {
          Toast.fail(res.msg);
        }
      });
    },
  },
  computed: {
    user() {
      return this.$store.state.sale;
    },
  },
};
</script>

<style scoped>
.button {
  width: 200px;
  height: 40px;
  border-width: 0px;
  border-radius: 3px;
  background: #1e90ff;
  cursor: pointer;
  outline: none;
  color: white;
  font-size: 17px;
  position: relative;
}

.integralTitle {
  line-height: 100px;
  flex: 1;
  text-align: center;
  font-size: 20px;
  margin-top: 50px;
}
</style>
