<template>
  <Nav title="退款/货详情" />
  <van-loading
    style="padding-top: 50px; display: flex; justify-content: center"
    v-if="!order"
  ></van-loading>
  <div class="refundbox" v-if="order">
    <div class="info-row">
      <div class="left-img">
        <img v-if="order.image" :src="order.image" alt="" />
      </div>
      <div class="right-info">
        <div class="info-title">{{ order.name }}</div>

        <div class="info-specification">{{ order.sname }}</div>

        <div class="info-price-row">
          <div class="info-price">{{ order.price }}</div>

          <div class="info-num">x{{ order.num }}</div>
        </div>
      </div>
    </div>
    <!--退款信息-->
    <div class="refund-detail">
      <div class="refund-ds"><span>退款金额：</span>￥{{ order.zprice }}</div>
      <div class="refund-ds"><span>退款原因：</span> {{ order.reason }}</div>
      <div class="refund-ds">
        <span>物流单号：</span> {{ order.logistics || "-" }}
      </div>
      <div class="refund-ds">
        <span>申请时间：</span> {{ order.reason_time }}
      </div>
      <div class="refund-ds">
        <span>售后状态：</span>
        <span v-if="order.status === 5" class="error">等待退款中</span>
        <span v-if="order.status === 6" class="error">退款已完成</span>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "./common/Nav.vue";
import { Api } from "../services/api";
export default {
  name: "RefundDetail",
  data() {
    return {
      order: null,
    };
  },
  components: {
    Nav,
  },
  computed: {
    id() {
      return ~~this.$route.params.id;
    },
  },
  mounted() {
    Api.orderDetail(this.id).then((res) => {
      this.order = res.data;
    });
  },
};
</script>

<style scoped>
.refundbox {
  background-color: #fff;
  height: auto;
  padding: 60px 15px 0;
}
.info-row {
  width: 100%;
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #ededed;
}
.refund-detail {
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.left-img {
  flex: 3;
  width: 93px;
  height: 93px;
}
.left-img img {
  width: 100%;
  height: 100%;
}
.right-info {
  flex: 6;
  padding-left: 10px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
}
.info-title {
  flex: 2;
  font-size: 16px;
  font-weight: bold;
}
.info-specification {
  flex: 3;
  color: #666666;
  padding-top: 13px;
}
.info-price {
  color: red;
  flex: 2;
}
.info-num {
  margin-right: 15px;
  margin-bottom: 3px;
}
.refund-ds {
  font-size: 14px;
  color: #333333;
  padding: 10px 0;
  border-bottom: 1px solid #ededed;
}
.refund-ds span {
  font-weight: bold;
  font-size: 15px;
}
.error {
  width: 100%;
  height: 35px;
  line-height: 35px;
  background-color: #fff;
  font-size: 15px;
  color: #f2504b;
}
.info-price-row {
  display: flex;
}
</style>
