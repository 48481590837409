<template>
<Nav title="订单详情" />
  <div
    v-if="fetchLoading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <van-loading type="circular" style="margin-top: 100px" />
  </div>
  <div v-if="order" style="padding-top: 50px">
    <!-- 订单编号、收货地址、收货人、联系方式、、是否优惠、实际付款 -->
    <!-- 商品图、商品名、商品规格、划线价、现价 -->
    <div class="info">
      <div class="info-img">
        <img :src="order.simage" alt="" />
      </div>
      <div class="right-row">
        <div class="right-title">{{ order.name }}</div>
        <div class="right-detail">{{ order.sname }}</div>
        <div class="right-size">x{{ order.num }}</div>
        <div class="right-price">
          合计：<span style="font-weight: bold; color: #d8585e">{{
            order.price
          }}</span>
        </div>
      </div>
    </div>
    <div class="info flex">
      <div class="row">
        <div class="title">收货人：</div>
        <div>{{ order.username }}</div>
      </div>
      <div class="row">
        <div class="title">联系方式：</div>
        <div>{{ order.phone }}</div>
      </div>
      <div class="row">
        <div class="title">订单编号：</div>
        <div class="cont">{{ order.code }}</div>
      </div>

      <div class="row">
        <div class="title">收货地址：</div>
        <div>{{ order.addr }}</div>
      </div>
      <div class="row">
        <div class="title">实际付款：</div>
        <div>{{ order.zprice }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "../services/api";
import Nav from "./common/Nav.vue";

export default {
  name: "OrderDetail",
  components: {
    Nav,
  },
  data() {
    return {
      order: null,
      fetchLoading: true,
    };
  },
  mounted() {
    Api.getOrderDetail(this.$route.params.id).then((res) => {
      this.fetchLoading = false;
      this.order = res.data;
    });
  },
};
</script>

<style scoped>
.info-img {
  width: 90px;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
}

.info-img img {
  width: 100%;
  height: auto;
}

.header-title {
  flex: 3;
  line-height: 44px;
  font-size: 16px;
  color: #464647;
}
.info {
  display: flex;
  padding: 15px 12px;
  background-color: #fff;
  width: 95%;
  margin: 10px auto;
  border-radius: 10px;
}

.flex {
  display: flex;
  flex-direction: column;
  font-size: 15px;
}

.title {
  width: 80px;
  text-align: right;
  color: #333333;
  white-space: nowrap;
}

.right-row {
  flex: 7;
  padding-left: 7px;
}

.right-title {
  color: #333333;
  font-weight: bold;
  font-size: 16px;
}

.right-detail {
  color: #666666;
  font-size: 14px;
  padding-top: 5px;
}

.right-size {
  text-align: right;
  font-size: 14px;
  color: #666666;
}

.right-price {
  text-align: right;
  font-size: 15px;
  color: #333333;
  padding-top: 10px;
}

.cont {
  width: 80%;
  padding-left: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  white-space: normal;
}
</style>
