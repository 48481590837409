<template>
  <Nav title="朋友圈" />
  <div style="height: 50px"></div>
  <div id="box">
    <div class="con">
      <div v-if="hospitals && hospitals.length == 0">
        <div class="icon-image">
          <img
            src="https://cdn.yukongkeji.cn/zaolu/20211225/3741dfc96219691d32937dbc284b7435.png"
            alt=""
          />
          <div class="icon-message">暂时没有相关数据</div>
          <div class="addhos" @click="showAddHospital">添加乡镇</div>
        </div>
      </div>

      <div v-else>
        <van-collapse
          v-model="activeName"
          is-link
          accordion
          class="hospital"
          @change="onHospitalChange"
        >
          <div class="loading" v-if="hosLoading" style="padding-top: 80px">
            <van-loading type="spinner" vertical>加载中...</van-loading>
          </div>
          <!-- 一级乡镇 -->
          <van-collapse-item
            v-show="!hosLoading"
            v-for="(item, index) in hospitals"
            :key="index"
            class="department"
            :name="item.id"
            @click="changeitem(item.id)"
            icon="https://cdn.yukongkeji.cn/zaolu/20211225/dada90a1bc55510131c7d92874163f46.svg"
          >
            <template #title>
              <div class="vanitem">
                {{ item.name
                }}<van-icon
                  size="20"
                  v-on:click.stop="edithos(item)"
                  name="records"
                ></van-icon>
              </div>
            </template>
            <!-- 二级村 -->
            <div
              v-if="
                item.departments && Object.keys(item.departments).length === 0
              "
            >
              <div class="icon-image">
                <img
                  src="https://cdn.yukongkeji.cn/zaolu/20211225/a7ac2acbf19573b627c015e6a7e523a6.png"
                  alt=""
                />
                <div class="icon-message">暂时没有相关村数据</div>
                <div class="addhos" @click="showAddDepartment">添加村</div>
              </div>
            </div>
            <div v-else>
              <van-collapse
                v-model="active"
                is-link
                accordion
                class="hospital"
                @change="onDepartmentChange"
              >
                <div class="loading" v-if="depLoading">
                  <van-loading type="spinner" vertical>加载中...</van-loading>
                </div>
                <van-collapse-item
                  v-for="(department, index) in item.departments"
                  :key="index"
                  class="department"
                  :name="`${department.id}`"
                  @click="changeitem(department.id)"
                  icon="https://cdn.yukongkeji.cn/zaolu/20211225/a15709e8ec1e17be2926fd7615b3d9e7.svg"
                >
                  <template #title>
                    <div class="vanitem">
                      {{ department.name
                      }}<van-icon
                        size="20"
                        v-on:click.stop="editdep(department)"
                        name="records"
                      ></van-icon>
                    </div>
                  </template>
                  <div
                    v-if="department.doctors && department.doctors.length === 0"
                    class="icon-image"
                  >
                    <img
                      src="https://cdn.yukongkeji.cn/zaolu/20211225/f893c50d99379eb256090c04f42e045c.png"
                      alt=""
                    />
                    <div class="icon-message">暂时没有相关主任/医生数据</div>
                    <div class="addhos" @click="showAddDoctor">
                      添加主任/医生
                    </div>
                  </div>
                  <div v-else>
                    <div class="loading" v-if="docLoading">
                      <van-loading type="spinner" vertical
                        >加载中...</van-loading
                      >
                    </div>
                    <div v-if="!docLoading">
                      <div
                        class="back"
                        v-for="(item, index) in department.doctors"
                        :key="index"
                      >
                        <div class="row-item">
                          <div class="center">
                            <div
                              class="top-name"
                              @click="comeinto(item.vip_image)"
                            >
                              {{ item.name }}
                              <span class="line">{{
                                item.director == "1" ? "主任" : "医生"
                              }}</span>
                            </div>
                            <span class="top-nick">{{ item.phone }}</span>
                            <span class="top-nick">{{ item.phone2 }}</span>
                            <span class="top-nick"
                              >会员码：<span
                                style="
                                  color: #f25860;
                                  text-decoration: underline;
                                "
                                @click="copy(item.vip_code)"
                                >{{ item.vip_code }}</span
                              ></span
                            >
                            <div class="bom-col pad_right">
                              <span>可兑积分：</span
                              ><span class="color">{{ item.rel_money }}</span>
                            </div>
                            <div class="bom-col">
                              <span>在途积分：</span
                              ><span class="color">{{ item.fast_money }}</span>
                            </div>
                          </div>
                          <div class="btn">
                            <a @click.stop="edit(item)" class="addedit"
                              ><img
                                src="https://cdn.yukongkeji.cn/zaolu/20211225/035b7f7a848a5dcf94a6642c4521d2f3.png"
                                style="width: 20px; height: 20px"
                                alt=""
                            /></a>
                            <a @click.stop="addcheck(item)" class="addedit"
                              ><img
                                src="https://cdn.yukongkeji.cn/zaolu/20211225/c73d87e4b56170d09f4a64cecd08655c.png"
                                style="width: 20px; height: 20px"
                                alt=""
                            /></a>
                            <a @click.stop="mx(item.uid)" class="addedit"
                              ><img
                                src="https://cdn.yukongkeji.cn/zaolu/20211225/90d50dae908a757b02ebf0425413855d.png"
                                style="width: 20px; height: 20px"
                                alt=""
                            /></a>
                            <a
                              @click.stop="qrcode(item.bind_qrcode)"
                              class="addedit"
                              ><img
                                src="https://cdn.yukongkeji.cn/zaolu/20211225/cb8bb39a244229cdbfd27932e59eb6cf.png"
                                style="width: 20px; height: 20px"
                                alt=""
                            /></a>
                          </div>
                        </div>
                        <div class="userInfo-extension">
                          <div class="userInfo-extension-info">
                            <table class="userInfo-extension-table">
                              <thead>
                                <tr>
                                  <th>近7天关注</th>
                                  <th>近7天转化数</th>
                                  <th>近7天转化率</th>
                                  <th>近30天销售额</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {{ item.seven_user }}
                                  </td>
                                  <td>
                                    {{ item.seven_money }}
                                  </td>
                                  <td>{{ item.rate }}%</td>
                                  <td>
                                    {{ item.three_money }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="addhos" @click="showAddDoctor">
                        添加主任/医生
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
                <div
                  class="addhos"
                  v-if="!depLoading"
                  @click="showAddDepartment"
                >
                  添加村
                </div>
              </van-collapse>
            </div>
          </van-collapse-item>
          <div
            class="addhos"
            v-if="!hosLoading"
            @click="showAddHospital"
            style="margin-bottom: 70px"
          >
            添加乡镇
          </div>
        </van-collapse>
      </div>
    </div>
    <!-- 添加乡镇信息 -->
    <van-popup v-model:show="addHospitalVisable" style="width: 90%">
      <div
        style="
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          width: 100%;
          padding-top: 20px;
        "
      >
        <input
          v-model="name"
          style="width: 80%; border: 0"
          placeholder="请输入乡镇名称"
        />
        <div style="margin: 16px; width: 90%">
          <van-button
                  ref="btn"
            :loading="addHospitalLoading"
            @click="addHospital"
            round
            size="small"
            block
            type="danger"
            native-type="submit"
            :disabled="!name || name.length === 1"
            >添加</van-button
          >
        </div>
      </div>
    </van-popup>
    <!-- 添加乡镇-村信息 -->
    <van-popup v-model:show="addDepartmentVisable" style="width: 90%">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          padding-top: 20px;
        "
      >
        <input
          v-model="name"
          style="width: 80%; border: 0"
          placeholder="请输入村名称"
        />
        <div style="margin: 16px; width: 90%">
          <van-button
            :loading="addDepartmentLoading"
            @click="addDepartment"
            round
            size="small"
            block
            type="danger"
            native-type="submit"
            :disabled="!name || name.length === 1"
            >添加</van-button
          >
        </div>
      </div>
    </van-popup>
    <!-- 添加乡镇-村-主任/医生信息 -->
    <van-popup v-model:show="addDoctorVisable" style="width: 90%">
      <van-form @submit="addDoctor">
        <van-field
          v-model="name"
          name="name"
          clearable
          label="姓名"
          placeholder="请输入姓名"
          style="height: 40px; line-height: 40px; padding: 0 10px"
        >
        </van-field>
        <van-field
              v-model="phone"
              type="tel"
              name="phone"
              clearable
              label="手机号"
              placeholder="请输入手机号"
              style="height: 40px; line-height: 40px; padding: 0 10px"
      ></van-field>
        <van-field
              v-model="phone2"
              type="tel"
              name="phone2"
              clearable
              label="手机号2"
              placeholder="请输入手机号"
              style="height: 40px; line-height: 40px; padding: 0 10px"
        ></van-field>
        <div
          name="director"
          label="身份类型"
          style="
            height: 40px;
            line-height: 40px;
            padding: 0 10px;
            display: flex;
          "
        >
          <div style="width: var(--van-field-label-width)">身份</div>
          <van-radio-group v-model="director" direction="horizontal">
            <van-radio name="1" checked-color="#ee0a24">主任</van-radio>
            <van-radio name="0" checked-color="#ee0a24">医生</van-radio>
          </van-radio-group>
        </div>
        <div class="upload-box" >
          <van-uploader name="image2" ref="uploadAdd" :max-count="1" @delete="deleteImageAdd" :before-read="beforeRead" :after-read="afterRead"  v-model="fileListAdd"/>
        </div>
        <div style="margin: 16px">
          <van-button
            :disabled="!name || !director"
            :loading="addDoctorLoading"
            round
            size="small"
            block
            type="danger"
            native-type="submit"
            >添加</van-button
          >
        </div>
      </van-form>
    </van-popup>
    <!-- 修改乡镇信息 -->
    <van-popup v-model:show="showhos" style="width: 90%">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          padding: 10px;
        "
      >
        <van-field
                v-model="hositem.name"
                name="name"
                clearable
                label="名称："
                placeholder="请输入名称"
                style="height: 40px; line-height: 40px; padding: 0 10px"
        >
        </van-field>
<!--        <div style="width: 100%;box-sizing: border-box;padding:10px 10px 0px 10px" >-->
<!--          <input-->
<!--                  v-model="hositem.name"-->
<!--                  name="name"-->
<!--                  style="width: 100%; border: 0"-->
<!--                  clearable-->
<!--                  placeholder="乡镇名称"-->
<!--          />-->
<!--        </div>-->

        <div style="margin: 16px; width: 90%">
          <van-button
            :loading="editHosLoading"
            round
            block
            type="danger"
            :disabled="!hositem.name"
            @click="editHospital"
            >提交
          </van-button>
        </div>
      </div>
    </van-popup>
    <!-- 修改村名称 -->
    <van-popup v-model:show="showoffice" style="width: 90%">
      <van-form @submit="editOffice">
        <van-field
          v-model="officeitem.name"
          name="name"
          clearable
          label="村名称"
          :rules="[{ required: true, message: '请填写用户名' }]"
          style="padding: 10px"
        >
        </van-field>
        <div style="margin: 16px">
          <van-button
            :loading="editHosLoading"
            round
            block
            type="danger"
            native-type="submit"
            >提交
          </van-button>
        </div>
      </van-form>
    </van-popup>
    <!-- 修改主任/医生信息 -->
    <van-popup v-model:show="show" style="width: 90%">
      <van-form @submit="editDoctor">
        <van-field
          v-model="name"
          name="name"
          clearable
          label="用户名"
          :rules="[{ required: true, message: '请填写用户名' }]"
          placeholder="请输入用户名"
          style="height: 40px; line-height: 40px; padding: 0 10px"
        >
        </van-field>
        <van-field
                v-model="phone"
                type="tel"
                name="phone"
                clearable
                label="手机号"
                placeholder="请输入手机号"
                style="height: 40px; line-height: 40px; padding: 0 10px"
        />
        <van-field
                v-model="phone2"
                type="tel"
                name="phone2"
                clearable
                label="手机号2"
                placeholder="请输入手机号"
                style="height: 40px; line-height: 40px; padding: 0 10px"
        />
        <div class="upload-box">
          <van-uploader name="image2" ref="uploadEdit" :max-count="1" @delete="deleteImageEdit" :before-read="beforeRead" :after-read="afterRead" v-model="fileList"/>
        </div>
        <div style="margin: 16px">
          <van-button
            :loading="editDoctorLoading"
            round
            block
            type="danger"
            native-type="submit"
            >提交
          </van-button>
        </div>
      </van-form>
    </van-popup>
    <!-- 核销积分 -->
    <van-popup v-model:show="checkmoneyVisable" style="width: 90%">
      <van-form @submit="gocheck">
        <van-field
          v-model="moneys"
          type="number"
          name="moneys"
          clearable
          label="核销数量"
          style="height: 40px; line-height: 40px; padding: 0 10px"
        >
        </van-field>
        <div style="margin: 16px">
          <van-button
            :disabled="!moneys || isNaN(moneys) || moneys <= 0"
            :loading="checkMoneyLoading"
            round
            block
            type="danger"
            native-type="submit"
            >立即核销</van-button
          >
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import Nav from "../common/Nav.vue";
import { SET_MANAGER_PAGE_POSITION } from "../../mutation-types";
import { SalemanApi } from "../../services/api";
export default {
  name: "SaleManager",
  components: {
    Nav,
  },
  activated() {
    if (this.version != localStorage.getItem("version")) {
      this.init();
      return;
    }
    window.addEventListener("scroll", this.savePosition, true);
    console.log("activated", this.position);
    if (this.position > 0) {
      console.log("scroll to top");
      document.getElementById("box").scrollTo(0, this.position);
    }
  },
  deactivated() {},
  computed: {
    position() {
      return this.$store.state.managerPagePosition;
    },
  },
  data() {
    return {
      hospitals: null, // 全部列表数据

      activeName: null, // 乡镇折叠组件
      active: null, // 村折叠组件

      hosLoading: true, // 乡镇列表加载中
      depLoading: false, // 村列表加载中
      docLoading: false, // 医生列表加载中

      addDoctorLoading: false, // 添加医生加载中
      addDepartmentLoading: false, // 添加村加载中
      addHospitalLoading: false, // 添加乡镇加载中
      editDoctorLoading: false, // 修改医生加载中
      checkMoneyLoading: false, // 核销积分加载中
      editHosLoading: false, //修改乡镇加载中
      editOfficeLoading: false, //修改村加载中

      showhos: false, //修改乡镇名称
      showoffice: false, //修改村名称

      name: null, // 名字
      phone: null, // 电话
      phone2:null, // 电话2
      image2:null,
      director: null, // 是否主任
      hositem: {}, //乡镇修改信息
      officeitem: {}, //村修改信息

      addHospitalVisable: false, // 添加乡镇模态框
      addDepartmentVisable: false, // 添加村模态框
      addDoctorVisable: false, // 添加医生模态框

      activeHospitalId: null, // 当前选中的乡镇id
      activeDepartmentId: null, // 当前选中的村id

      accordion: true,
      show: false,
      checkmoneyVisable: false, // 核销积分模态框

      checkDoctor: null, // 当前要核销的医生
      uid: "",
      moneys: "",
      version: "",

      // 上传图片
      imagePath:'',
      fileList:[],
      fileListAdd:[],
    };
  },
  mounted() {
    let version = Date.now();
    localStorage.setItem("version", version);
    this.version = version;
    window.addEventListener("scroll", this.savePosition, true);
    console.log("mounted");
    this.init();
  },
  methods: {
    init() {
      this.activeName = "";
      this.active = "";
      SalemanApi.getHospitalList()
        .then((res) => {
          this.hosLoading = false;
          if (res.status == 1) {
            this.hospitals = res.data;
          } else {
            this.hospitals = [];
            Toast.fail(res.msg);
          }
          window.scrollTo(0, 0);
        })
        .catch(() => {
          this.hosLoading = false;
          Toast.fail("网络错误");
        });
    },
    savePosition() {
      console.log(document.getElementById("box").scrollTop);
      if (document.getElementById("box").scrollTop > 0) {
        this.$store.commit({
          type: SET_MANAGER_PAGE_POSITION,
          payload: document.getElementById("box").scrollTop,
        });
      }
    },
    mx(id) {
      window.removeEventListener("scroll", this.savePosition, true);
      if (!id) {
        this.$router.push("/saleman/bill");
      } else {
        this.$router.push(`/saleman/bill/${id}`);
      }
    },

    // 添加乡镇
    showAddHospital() {
      this.addHospitalVisable = true;
      this.clearData();
    },
    // 添加村
    showAddDepartment() {
      this.addDepartmentVisable = true;
      this.clearData();
    },
    // 添加主任/医生
    showAddDoctor() {
      this.addDoctorVisable = true;
      this.clearData();
    },
    addHospital() {
      this.addHospitalLoading = true;
      SalemanApi.addHospital({
        name: this.name,
      })
        .then((res) => {
          this.addHospitalLoading = false;
          if (res.status == 1) {
            this.hospitals.unshift(res.data);
            this.addHospitalVisable = false;
            this.name = null;
          } else {
            Toast.fail(res.msg);
          }
        })
        .catch(() => {
          this.addHospitalLoading = false;
          Toast.fail("网络错误");
        });
    },
    addDepartment() {
      console.log(this.activeHospitalId);
      this.addDepartmentLoading = true;
      SalemanApi.addDepartment(this.activeHospitalId, {
        name: this.name,
      })
        .then((res) => {
          this.addDepartmentLoading = false;
          if (res.status == 1) {
            for (let i = 0; i < this.hospitals.length; i++) {
              if (this.hospitals[i].id == this.activeHospitalId) {
                this.hospitals[i].departments.unshift(res.data);
                break;
              }
            }
            this.hospitals = [...this.hospitals];
            this.addDepartmentVisable = false;
            this.name = null;
          } else {
            Toast.fail(res.msg);
          }
        })
        .catch(() => {
          this.addDepartmentLoading = false;
          Toast.fail("网络错误");
        });
    },
    addDoctor() {
      this.addDoctorLoading = true;
      SalemanApi.addDoctor(this.activeDepartmentId, {
        name: this.name,
        phone: this.phone,
        phone2: this.phone2,
        image2: this.imagePath,
        director: this.director,
      })
        .then((res) => {
          this.addDoctorLoading = false;
          if (res.status == 1) {
            for (let i = 0; i < this.hospitals.length; i++) {
              if (this.hospitals[i].id == this.activeHospitalId) {
                for (let j = 0; j < this.hospitals[i].departments.length; j++) {
                  if (
                    this.hospitals[i].departments[j].id ==
                    this.activeDepartmentId
                  ) {
                    this.hospitals[i].departments[j].doctors.unshift(res.data);
                    break;
                  }
                }
                break;
              }
            }
            this.addDoctorVisable = false;
            this.hospitals = [...this.hospitals];
            this.name = null;
            this.phone = null;
            this.director = null;
          } else {
            Toast.fail(res.msg);
          }
        })
        .catch(() => {
          this.addDoctorLoading = false;
          Toast.fail("网络错误");
        });
    },
    // 切换乡镇事件
    onHospitalChange(hospitalId) {
      console.log("onHospitalChange", hospitalId);
      this.activeHospitalId = hospitalId;
      if (!hospitalId) return;
      for (let hospital of this.hospitals) {
        if (hospital.id == hospitalId && hospital.departments) {
          return;
        }
      }
      this.depLoading = true;
      SalemanApi.getDepartmentList(hospitalId)
        .then((res) => {
          this.depLoading = false;
          if (res.status == 1) {
            let newHospitals = [];
            for (let hospital of this.hospitals) {
              if (hospital.id == hospitalId) {
                hospital.departments = res.data;
              }
              newHospitals.push(hospital);
            }
            this.hospitals = newHospitals;
          } else {
            Toast.fail(res.msg);
          }
        })
        .catch(() => {
          this.depLoading = false;
          Toast.fail("网络错误");
        });
    },
    onDepartmentChange(departmentId) {
      this.activeDepartmentId = departmentId;
      if (!departmentId) return;
      let n = -1;
      let i = -1;
      console.log("onDepartmentChange", departmentId);
      let newHospitals = [...this.hospitals];
      for (let hospital of this.hospitals) {
        i++;
        if (!hospital.departments) {
          continue;
        }
        n = -1;
        for (let department of hospital.departments) {
          n++;
          if (department.id == departmentId) {
            if (department.doctors) return;
            // 异步获取数据
            this.docLoading = true;
            SalemanApi.getDoctors(departmentId)
              .then((res) => {
                this.docLoading = false;
                if (res.status == 1) {
                  console.log(newHospitals[i], i, n);
                  newHospitals[i].departments[n].doctors = res.data;
                  this.hospitals = [...newHospitals];
                } else {
                  Toast.fail(res.msg);
                }
              })
              .catch((e) => {
                console.error(e);
                this.docLoading = false;
                Toast.fail("网络错误");
              });
            return;
          }
        }
      }
    },
    // 触发item
    changeitem(e) {
      console.log("这里触发item", e);
    },
    clearData() {
      this.uid = null;
      this.name = "";
      this.phone = "";
      this.phone2 = "";
      this.imagePath = '';
      this.image2 = '';
      this.fileListAdd = [];
      this.fileList = [];
      this.director = null;
      this.show = false;
    },
    // 修改乡镇名称
    edithos(item) {
      this.hositem = { ...item };
      this.showhos = true;
    },
    editHospital() {
      this.editHosLoading = true;
      const { name } = this.hositem;
      const id = this.hositem.id;
      SalemanApi.saveHospital(id, {
        name,
      })
        .then((res) => {
          this.editHosLoading = false;
          console.log(res);
          if (res.status == 1) {
            this.showhos = false;
            Toast.success("修改乡镇名称成功");
            for (let i = 0; i < this.hospitals.length; i++) {
              if (!this.hospitals[i]) continue;
              if (this.hospitals[i].id == id) {
                this.hospitals[i] = this.hositem;
                return;
              }
            }
          } else {
            Toast.fail(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.editHosLoading = false;
          Toast.fail("网络错误");
        });
    },
    // 修改村名称
    editdep(item) {
      this.officeitem = { ...item };
      this.showoffice = true;
    },
    editOffice(values) {
      this.editOfficeLoading = true;
      const { name } = values;
      const id = this.officeitem.id;
      SalemanApi.saveDepartment(id, {
        name,
      })
        .then((res) => {
          this.editOfficeLoading = false;
          console.log(res);
          if (res.status == 1) {
            this.showoffice = false;
            Toast.success("修改村名称成功");
            let newHospitals = [...this.hospitals];
            for (let i = 0; i < this.hospitals.length; i++) {
              if (!this.hospitals[i].departments) continue;
              for (let j = 0; j < this.hospitals[i].departments.length; j++) {
                if (!this.hospitals[i].departments[j]) continue;
                if (newHospitals[i].departments[j].id == id) {
                  newHospitals[i].departments[j] = this.officeitem;
                  this.hospitals = [...newHospitals];
                  return;
                }
              }
            }
          } else {
            Toast.fail(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.editOfficeLoading = false;
          Toast.fail("网络错误");
        });
    },
    // 修改主任/医生信息
    edit(item) {
      // 姓名、手机号、主任/医生类型、
      this.uid = item.uid;
      this.name = item.name;
      this.phone = item.phone;
      this.phone2 = item.phone2;
      this.image2 = item.image2;
      this.show = true;
      this.fileList[0] = {
        url: item.image2
      };
    },
    editDoctor(values) {
      this.editDoctorLoading = true;
      const { name, phone, phone2 } = values;
      console.log(values)
      const uid = this.uid;
      const image2 = this.imagePath;
      console.log(uid, name, phone, phone2,image2);
      SalemanApi.saveDoctor(uid, {
        name,
        phone,
        phone2,
        image2
      })
        .then((res) => {
          console.log(res.status);
          if (res.status == 1) {

            let newHospitals = [...this.hospitals];
            for (let i = 0; i < this.hospitals.length; i++) {
              if (!this.hospitals[i].departments) continue;
              for (let j = 0; j < this.hospitals[i].departments.length; j++) {
                if (!this.hospitals[i].departments[j].doctors) continue;
                for (
                  let k = 0;
                  k < this.hospitals[i].departments[j].doctors.length;
                  k++
                ) {
                  if (newHospitals[i].departments[j].doctors[k].uid == uid) {
                    newHospitals[i].departments[j].doctors[k].name = name;
                    newHospitals[i].departments[j].doctors[k].phone = phone;
                    newHospitals[i].departments[j].doctors[k].phone2 = phone2;
                    newHospitals[i].departments[j].doctors[k].image2 = image2;
                    this.editDoctorLoading = false;
                    this.show = false;
                    this.hospitals = [...newHospitals];
                    return;
                  }
                }
              }
            }

          } else {
            Toast.fail(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.editDoctorLoading = false;
          Toast.fail("网络错误");
        });
    },
    // 复制文本
    copy(text) {
      console.log("进入复制事件", text);
      var oInput = document.createElement("textarea"); //'textarea'
      document.body.appendChild(oInput);
      oInput.value = text;
      oInput.focus();
      oInput.select();
      document.execCommand("Copy");
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      Toast.success("复制成功");
    },
    // 核销积分
    addcheck(item) {
      console.log("核销信息", item);
      this.checkmoneyVisable = true;
      this.checkDoctor = item;
    },
    gocheck(res) {
      const { uid, rel_money } = this.checkDoctor;
      const checkMoney = parseFloat(res.moneys);
      if (typeof checkMoney !== "number") {
        Toast.fail("格式错误");
        return;
      }
      let new_money = parseFloat(res.moneys).toFixed(2);
      if (new_money > rel_money) {
        Toast.fail("积分不足！");
      } else {
        this.checkMoneyLoading = true;
        console.log(uid, new_money);
        SalemanApi.saveDoctor(uid, {
          money: new_money,
          id: uid,
        })
          .then((res) => {
            this.checkMoneyLoading = false;
            if (res.status == 1) {
              Toast.success("操作成功");
              this.checkmoneyVisable = false;
              let data = res.data;
              for (let i = 0; i < this.hospitals.length; i++) {
                if (!this.hospitals[i].departments) continue;
                for (let j = 0; j < this.hospitals[i].departments.length; j++) {
                  if (!this.hospitals[i].departments[j].doctors) continue;
                  for (
                    let k = 0;
                    k < this.hospitals[i].departments[j].doctors.length;
                    k++
                  ) {
                    if (
                      this.hospitals[i].departments[j].doctors[k].uid == uid
                    ) {
                      this.hospitals[i].departments[j].doctors[k].rel_money =
                        data.rel_money;
                      return;
                    }
                  }
                }
              }
            } else {
              Toast.fail(res.msg);
            }
          })
          .catch((e) => {
            console.error(e);
            Toast.fail("网络错误");
          });
      }
    },
    qrcode(url) {
      console.log("进入二维码", url);
      if (!window.WeixinJSBridge) {
        Toast.fail("请在微信中打开");
      }
      window.WeixinJSBridge.invoke("imagePreview", {
        current: url,
        urls: [url],
      });
    },
    // 生成名片
    comeinto(vip_image) {
      console.log("进入生成名片", vip_image);
      if (!window.WeixinJSBridge) {
        Toast.fail("请在微信中打开");
      }
      if (vip_image) {
        window.WeixinJSBridge.invoke("imagePreview", {
          current: vip_image,
          urls: [vip_image],
        });
      }
    },
    //上传文件
    beforeRead (file) {
      if (file instanceof Array && file.length) {
        file.forEach(item => {
          if (item.type !== 'image/jpeg' && item.type !== 'image/png' && item.type !== 'image/jpg') {
            this.$toast.fail('请选择正确图片格式上传')
            return false
          }
        })
        return file
      } else {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
          this.$toast.fail('请选择正确图片格式上传')
          return false
        }
        return file
      }
    },
    deleteImageEdit(){
      console.log(this.$refs.uploadEdit)
      this.$nextTick(() => {
        this.$refs.uploadEdit.chooseFile();
      });
    },
    deleteImageAdd(){
      console.log(this.$refs.uploadAdd)
      this.$nextTick(() => {
        this.$refs.uploadAdd.chooseFile();
      });
    },
    async afterRead (file) {
      let formData = new FormData();
      formData.append("image",file.file)
      const response = fetch('/api/upload', {
        method:"POST",
        body:formData
      })
      .then(response => response.json())
      .then(result => {
        console.log('Success:', result.data);
        // this.image2[0] = {
        //   url:  result.data
        // }
        this.imagePath = result.data
        console.log(this.imagePath)
      })
      .catch(error => {
        console.log('Error:', error);
      });
    },


  },
};
</script>

<style>
#box {
  height: 100%;
  overflow-y: scroll;
  /* overflow: auto; */
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding-bottom: 30px;
}
body {
  padding: 0;
  margin: 0;
}

.nav {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.nav li {
  list-style: none;
  flex: 1;
  text-align: center;
}

.nav li a {
  width: 50px;
  height: 30px;
  line-height: 30px;
}

a {
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: orange;
}

a {
  color: #000;
}

.btn {
  display: flex;
  margin-left: auto;
  margin-right: 5px;
  border-width: 0;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  font-family: Microsoft YaHei;
  color: white;
}

.box li {
  width: 200px;
  height: 200px;
  float: left;
  background-color: #008000;
  list-style: none;
}

.box li:nth-child(2) {
  background-color: #0000ff;
}

.row-item {
  border-radius: 5px;
  align-items: center;
  display: flex;
  padding: 3px 5px;
}

.item-name {
  width: 50px;
  height: 50px;
  margin-left: 15px;
}

.item-name img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bom-row {
  display: flex;
  text-align: center;
  font-size: 14px;
}

.bom-col {
  text-align: center;
  display: flex;
  color: #333333;
}

.pad_right {
  padding-right: 10px;
}

.color {
  color: #f25860;
}

.login-button {
  width: 270px;
  height: 40px;
  border-width: 0px;
  border-radius: 3px;
  background: #1e90ff;
  cursor: pointer;
  outline: none;
  font-family: Microsoft YaHei;
  color: white;
  font-size: 17px;
  flex: 5;
}

.login-button:hover {
  background: #5599ff;
}

.bottom-btn {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.right-all {
  flex: 4;
  display: flex;
}

.bottom-left-all {
  font-size: 18px;
  line-height: 40px;
}

.input-all {
  display: flex;
  margin: 7px;
  border-width: 0;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  font-family: Microsoft YaHei;
  color: white;
  margin-left: 10px;
}

.header {
  width: 100%;
  height: 44px;
  display: flex;
  background-color: #fff;
}

.left {
  flex: 2;
  line-height: 44px;
  display: flex;
}

.left-img {
  margin-top: 3px;
}

.left img {
  width: 18px;
  height: 18px;
  flex: 3;
}

.left span {
  flex: 2;
  margin-left: 10px;
}

* {
  padding: 0;
  margin: 0;
}

body {
  background-color: #f2f2f2;
}

.header {
  width: 100%;
  height: 44px;
  background-color: #fff;
  display: flex;
  text-align: center;
  font-family: "SimHei";
  font-weight: 600;
  position: fixed;
  top: 0;
  z-index: 999;
}

.header-img {
  width: 30px;
  height: 34px;
  margin-top: 5px;
}

.header-title {
  flex: 1;
  line-height: 44px;
  font-size: 16px;
  color: #464647;
  margin-right: 30px;
}

.userInfo-extension-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-size: 14px;
}

.userInfo-extension-table th,
.userInfo-extension-table td {
  padding: 5px;
  font-size: 14px;
  color: #333333;
  border: 0.5px solid #ddd;
}

.userInfo-extension-table th {
  font-size: 13px;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
  background-color: #ededed;
}

.addedit {
  padding: 2px 4px;
  border-width: 0px;
  border-radius: 12px;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  text-align: center;
  margin-left: 10px;
}

/* 折叠面板 */
.conbox {
  background-color: #fff;
  padding: 5px 15px;
}

.department {
  border-bottom: 1px solid #ededed;
  color: #333333;
}

.back {
  background-color: #fff;
  padding-top: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ededed;
}

.top-name {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
}

.top-nick {
  font-size: 14px;
  color: #666666;
}

.con {
  padding-bottom: 10px;
  /* padding-top: 50px; */
}

.van-form {
  height: auto;
}

.van-cell__title {
  text-align: left;
  width: auto;
}

.addhos {
  width: 150px;
  margin: 25px auto;
  padding: 6px 10px;
  text-align: center;
  color: #f25860;
  font-size: 14px;
  border-radius: 20px;
  border: 1px solid #f25860;
  background-color: #fff;
}

.icon-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  color: #8a8a8a;
  padding-top: 5px;
}

.icon-image img {
  width: 50px;
  height: 50px;
}
input {
  background: none;
  outline: none;
  border: 1px solid #ccc;
}
input:focus {
  border: none;
}

.line {
  border: 1px solid #f25860;
  color: #f25860;
  font-weight: lighter;
  border-radius: 20px;
  font-size: 8px;
  padding: 1px 3px;
}

.van-collapse-item--border::after {
  /* border-bottom: 1px solid #ebedf0; */
  border-top: none;
}

[class*="van-hairline"]::after {
  border: none;
}

textarea {
  opacity: 0;
}

.loading {
  margin-top: 20px;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.van-collapse-item__content {
  padding-top: 0;
}

.vanitem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.van-icon-records {
  margin-left: 4px;
}
  .upload-box{
    box-sizing: border-box;
    padding: 10px;
  }

</style>
<style scoped>
  /deep/ .van-uploader__preview-delete{
    width: 50px!important;
    height: 20px!important;
    top: 59px;
    right: -230px;
    border-radius: 5px;
    background: #67C23A;
  }
  /deep/ .van-uploader__preview-delete-icon{
    top: 3px;
    left: -3px;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  /deep/ .van-icon-cross:before{
    content: '更改';
  }
  /deep/ .van-form{
    box-sizing: border-box;
    padding: 10px;
  }
</style>
