<template>
  <span></span>
</template>

<script>
import { Toast } from "vant";
import { Api } from "../services/api";
export default {
  name: "Article",
  computed: {
    id() {
      return ~~this.$route.params.id;
    },
  },
  mounted() {
    Api.getArticle(this.id).then((res) => {
      if (res.status === 1) {
        location.href = res.data;
      } else {
        Toast.fail(res.msg);
      }
    });
  },
};
</script>

<style scoped></style>
