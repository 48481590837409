import { createApp } from "vue";
import {
  Picker,
  Popup,
  Swipe,
  SwipeItem,
  ActionSheet,
  Loading,
  PullRefresh,
  NavBar,
  Image,
  Button,
  Tab,
  Tabs,
  DatetimePicker,
  Radio,
  RadioGroup,
  Form,
  Field,
  Collapse,
  CollapseItem,
  Icon,
  Uploader,
} from "vant";
import App from "./App.vue";
import store from "./store";
import Router from "./route";
import './assets/font/font.css'
const app = createApp(App);
app.use(store);
app
  .use(Picker)
  .use(Popup)
  .use(Swipe)
  .use(SwipeItem)
  .use(ActionSheet)
  .use(PullRefresh)
  .use(NavBar)
  .use(Image)
  .use(Button)
  .use(Tab)
  .use(Tabs)
  .use(Radio)
  .use(RadioGroup)
  .use(Form)
  .use(Field)
  .use(Collapse)
  .use(CollapseItem)
  .use(Icon)
  .use(DatetimePicker)
  .use(Loading)
  .use(Uploader)
app.use(Router);
app.mount("#app");
