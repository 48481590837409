<template>
  <Nav title="明细" />
  <div class="mxPage">
    <div class="tabtime">
      <span style="padding-right: 5px" @click="selectDateLimit">{{
        dateLimitStr
      }}</span>
      <van-image
        @click="selectDateLimit"
        width="16"
        height="11"
        src="https://cdn.yukongkeji.cn/zaolu/20211225/45064695df28e5f63501613578f2cf37.png"
      />
    </div>
    <div v-if="list" class="list-all">
      <div class="list-item" v-for="item in list" :key="item.id">
        <div class="time">
          {{ formatTime(item.time) }}
        </div>
        <div class="colnum">
          <div class="amount">
            {{ item.type === 1 ? "-" : "+" }}{{ item.money }}
          </div>
          <div>{{ item.name }}({{ item.vip_code }})</div>
        </div>
      </div>
    </div>
    <div style="width: 100%, text-align: center, margin-top: 20px"></div>
  </div>
  <van-popup position="bottom" v-model:show="show">
    <van-tabs v-model:active="active">
      <van-tab title="开始时间" style="width: 100%, height: 100%">
        <van-datetime-picker
          v-model="startDate"
          type="date"
          title=""
          :min-date="startMinDate"
          :max-date="startEndDate"
          @confirm="onStartDateOk"
        />
      </van-tab>
      <van-tab title="结束时间" style="width: 100%, height: 100%">
        <van-datetime-picker
          v-model="endDate"
          type="date"
          title=""
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onEndDateOk"
        />
      </van-tab>
    </van-tabs>
  </van-popup>
</template>

<script>
import moment from "moment";
import { SalemanApi } from "../../services/api";
import Nav from "../common/Nav.vue";
import { computed } from "vue-demi";
import { Toast } from "vant";
export default {
  name: "BillDoctor",
  setup() {
    const formatTime = computed(() => {
      return (time) => moment(time).format("YYYY-MM-DD HH:mm:ss");
    });
    window.scrollTo(0, 0);
    return {
      formatTime,
    };
  },
  components: { Nav },
  data() {
    return {
      list: null,
      startDate: null,
      endDate: null,
      minDate: this.startDate || new Date(2020, 0, 1),
      maxDate: new Date(),
      dateLimitStr: "近三个月",
      show: false,
      active: 0,
    };
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    selectDateLimit() {
      this.show = true;
    },
    onStartDateOk() {
      this.active = 1;
      console.log(this.startDate);
      this.minDate = this.startDate;
    },
    onEndDateOk() {
      if (this.startDate.getTime() > this.endDate.getTime()) {
        Toast.fail("开始日期不能大于结束日期");
        return;
      }
      this.active = 0;
      this.show = false;
      this.fetchData();
    },
    async fetchData() {
      this.list = [];
      const data = { code: this.code };
      if (this.startDate) {
        data.start_time = this.startDate.getTime();
      }
      if (this.endDate) {
        data.end_time = this.startDate.getTime();
      }
      const res = await SalemanApi.getDoctorBillList(data);
      if (res.status === 1) {
        this.list = res.data.bill_list;
        this.minDate = moment(
          res.data.time_limit.start_time,
          "YYYY-MM-DD"
        ).toDate();
        this.startMinDate = moment(
          res.data.time_limit.start_time,
          "YYYY-MM-DD"
        ).toDate();
        this.startEndDate = moment(
          res.data.time_limit.end_time,
          "YYYY-MM-DD"
        ).toDate();
        this.maxDate = moment(
          res.data.time_limit.end_time,
          "YYYY-MM-DD"
        ).toDate();
        if (this.startDate && this.endDate) {
          this.dateLimitStr =
            moment(this.startDate).format("YYYY-MM-DD") +
            "至" +
            moment(this.endDate).format("YYYY-MM-DD");
        }
      } else {
        Toast.fail(res.msg);
      }
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

.list-item {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  border-radius: 3px;
  justify-content: space-between;
}

.time {
  font-size: 14px;
  color: #666666;
}

.colnum {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.share {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 23px !important;
  height: 23px !important;
}

.num1 {
  color: #333333;
  text-align: right;
  font-weight: bold;
  font-size: 16px;
}

.zrname {
  font-size: 14px;
  color: #333333;
  text-align: right;
}

.list-all {
  height: auto;
  width: 97%;
  margin: 0 auto;
}

.mxPage {
  padding: 0;
  height: 0;
  background-color: #f2f2f2;
  min-height: 100%;
  margin-top: 50px;
  overflow-y: scroll;
}

.extra {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 14px;
  color: #666666;
}

.item {
  font-size: 14px;
  color: #666666;
}

.amount {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.tabtime {
  padding: 5px 10px;
  background-color: #fff;
  color: #333333;
  font-size: 17px;
  font-weight: bold;
  margin-top: 2px;
  position: relative;
}
</style>
