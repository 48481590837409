<template>
  <div
    v-if="!product"
    style="text-align: center; width: 100%; margin-top: 30px"
  >
    <van-loading type="circular">加载中...</van-loading>
  </div>
  <div v-if="product" id="box">
    <div>
      <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in product.banner" :key="item">
          <img
            :src="item.image"
            style="width: 100%; height: auto; min-height: 100px"
          />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 标题 -->
    <div class="title-bar">{{ product.ware.name }}</div>
    <!-- 价格 -->
    <div class="price">
      <span class="price-new"
        >会员价：{{ product.ware.xprice }}/盒(30天用量)</span
      >
      <span class="price-del">零售价:{{ product.ware.price }}</span>
    </div>

    <!-- 推荐理由 -->
    <div class="recommend">
      <div>
        <p>{{ product.ware.fare == 0 ? "免运费" : "" }}</p>
      </div>
      <div>
        <span>已售 {{ product.ware.sales }}</span>
      </div>
    </div>
    <!-- 推荐购买 -->
    <div class="excuse">推荐理由</div>
    <!-- 推荐理由列表 -->
    <div class="message-box-row">
      <div class="message-box">
        <div
          class="box-item"
          v-for="(spec, index) in product.specs"
          :key="spec.id"
        >
          <span style="color: #e86f74">{{ index }}、</span>{{ spec.content }}
        </div>
        <div class="triangle-border tb-background"></div>
      </div>
    </div>

    <van-action-sheet style="box-sizing: border-box;padding: 7px 0px 10px 5px;" v-model:show="drawer" :title="null">
      <div class="product-image">
        <div class="image-left">
          <img :src="currentSpec ? currentSpec.simage : ''" alt="" />
        </div>
        <div class="image-right">
          <div class="right-new-used-row">
            <div class="right-new-price Alibaba-PuHuiTi-Bold">
              会员价:￥<span>{{ currentSpec ? parseInt(currentSpec.sprice) : "-" }}</span>
            </div>
            <div class="right-used-price Alibaba-PuHuiTi-Regular">
              零售价:&nbsp;&nbsp;<span>{{ currentSpec ? parseInt(currentSpec.syprice) : "-" }}</span>
            </div>
          </div>


        </div>
      </div>
      <div class="product-specification" v-if="product.wares_spec">
        <div class="specification-title Alibaba-PuHuiTi-Regular">规格</div>
        <div class="specification-item-row">
          <div
            v-for="(item, index) in product.wares_spec"
            :key="index"
            :class="
              currentSpec.id == item.id
                ? 'actively specification-active feed_item Alibaba-PuHuiTi-Regular'
                : 'specification-active feed_item Alibaba-PuHuiTi-Regular'
            "
            @click="onSpecChange(item)"
            data-id="item.id"
          >
            <span class="Alibaba-PuHuiTi-Regular">{{ item.sname }}</span>
          </div>
        </div>
<!--        <div class="used-price">-->
<!--          <span>零售价:217元/盒（30天用量）</span>-->
<!--        </div>-->
      </div>
      <div class="product-conutet">
        <div class="product-num Alibaba-PuHuiTi-Regular">购买数量:</div>
        <div class="product-row1">
          <div class="for-num">
            <div id="num-jian" @click="numjian" class="product-left subtract"></div>
            <input
              type="text"
              class="product-center"
              placeholder="设定"
              v-model="buyCount"
            />
            <div id="num-jia" @click="numjia" class="product-right add"></div>
          </div>
<!--          <span> / 盒 </span>-->
        </div>
      </div>
      <div class="center">
        <a @click="buy()" class="product-shop">立即购买</a>
      </div>
    </van-action-sheet>
    <!-- 详情图片 -->
    <div
      class="detail-img"
      style="max-width: 100%"
      v-html="markup"
    ></div>
<!--    <div>-->
<!--      <img :src="JSON.parse(product.ware.content).entityMap[0].data.src" alt="">-->
<!--    </div>-->

    <!-- 底部加入个人中心及立即购买 -->
    <div class="function_box tabbar">
      <div class="function_btn1" @click="linkUser">
        <img
          src="https://cdn.yukongkeji.cn/zaolu/20211225/91b49addc4a67142a7eba8722ae2a1f2.png"
          width="14"
          height="14"
        />
        个人中心
      </div>
      <div @click="onShowBuy" id="alert" class="function_btn">
        <img
          src="https://cdn.yukongkeji.cn/zaolu/20211225/72cf4727b2c095747384803d67efd813.png"
        />
        立即购买
      </div>
    </div>
  </div>
</template>

<script>
import {
  SET_PRODUCT_MUTATION,
  SET_CURRENT_WARE_SPEC_MUTATION,
  ADD_BUY_COUNT_MUTATION,
  SUB_BUY_COUNT_MUTATION,
} from "../mutation-types";
import { Api } from "../services/api";
import draftToHtml from 'draftjs-to-html';

export default {
  name: "Product",
  components: {},
  data() {
    return {
      drawer: false,
      show: false,
      list: [],
      syprice: "",
      sname: "",
      sprice: "",
      image: "",
      sid: "",
      markup:''

    };
  },
  activated() {
    console.log("activated");
    this.fetchData();
  },
  deactivated() {
    console.log("deactivated");
  },
  computed: {
    product() {
      // return JSON.parse(this.$store.state.product.ware.content).entityMap[0].data.src
      return this.$store.state.product
    },
    currentSpec() {
      console.log(this.$store.state.currentWareSpec);
      return this.$store.state.currentWareSpec;
    },
    buyCount() {
      return this.$store.state.buyCount;
    },
  },
  mounted() {
    console.log("Product mounted");
    this.fetchData();
    Api.isVip().then((res) => {
      if (res.data === 0) {
        this.$router.push({
          path: "/user/vip",
        });
      }
    });
  },
  methods: {
    objToHtml(){
      let rawContentState = JSON.parse(this.$store.state.product.ware.content)
      console.log(rawContentState,2121212)
      let hashConfig={
        trigger:'#',
        separator: ' ',
      }
      let directional = true
      let customEntityTransform = ''
      this.markup = draftToHtml(
              rawContentState,
              hashConfig,
              directional,
              customEntityTransform
      );
      console.log(this.markup,1111)
    },

    fetchData() {
      Api.getProductDetail().then((res) => {
        this.$store.commit({
          type: SET_PRODUCT_MUTATION,
          payload: res.data,
        });
        if (!this.$store.state.currentWareSpec) {
          this.$store.commit({
            type: SET_CURRENT_WARE_SPEC_MUTATION,
            payload: res.data.wares_spec[0],
          });
          this.objToHtml();
        }
      });

    },
    linkUser() {
      this.$router.replace({
        path: "/user",
      });
    },
    onSpecChange(item) {
      this.$store.commit({
        type: SET_CURRENT_WARE_SPEC_MUTATION,
        payload: item,
      });
    },
    numjia() {
      this.$store.commit({
        type: ADD_BUY_COUNT_MUTATION,
      });
    },
    numjian() {
      if (this.buyCount > 1) {
        this.$store.commit({
          type: SUB_BUY_COUNT_MUTATION,
        });
      }
    },
    itemClick() {
      this.show = true;
    },
    onShowBuy() {
      this.drawer = true;
    },
    buy() {
      this.$router.push({
        path: "/order-confirm",
      });
    },

  },
};
</script>

<style>
.detail-img img {
  width: 100%;
}
</style>

<style scoped>
* {
  box-sizing: border-box;
}
body {
  line-height: 1.5;
  font-family: Arial, sans-serif;
}

.el-carousel__indicators--horizontal {
  width: 100%;
  text-align: center;
  bottom: 20px;
}

.el-drawer__body {
  padding: 0;
}

.right-new-used-row {
  display: flex;
  /*margin-top: 5px;*/
  margin: 8px 0px 8px 0px;
}

.right-new-price {
  font-size: 18px;
  font-weight: bold;
  color: #e13940;
}

.product-conutet {
  display: flex;
  width: 100%;
  padding-top: 10px;
  border-top: 1px solid #ededed;
}

.product-num {
  padding-left: 10px;
  font-size: 15px;
  color: #666666;
  font-weight: bold;
  width: 150px;
  align-self: center;
}

.right-used-price {
  text-decoration: line-through;
  color: #999999;
  font-size: 14px;
  margin-left: 10px;
   margin-top: 2px;
}

.product-row1 {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: flex-end;
  padding-right: 40px;
  font-size: 16px;
}

.for-num {
  border: 1px solid #ededed;
  background-color: #f2f3f5;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  margin-right: 8px;
}

.product-row1 span {
  padding-top: 5px;
}

.product-left {
  width: 40px;
  text-align: center;
  /* margin-right: 2px; */
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-center {
  width: 40px;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  color: #666666;
  text-align: center;
  border: none;
}

.product-right {
  width: 40px;
  text-align: center;
  /*margin-left: 2px;*/
  font-weight: 700;
  /*padding-top: 2px;*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-shop {
  background: #d8585e;
  color: #fff !important;
  border-radius: 18px;
  text-align: center;
  font-family: "Microsoft JhengHei";
  font-size: 16px;
  padding: 8px 80px;
}

.right-num span {
  font-size: 14px;
  color: #666666;
  margin-top: 5px;
}

#box {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: env(safe-area-inset-bottom);
  overflow-y: scroll;
  height: 100%;
}

.specification-title {
  padding-left: 10px;
  font-size: 15px;
  color: #666666;
  font-weight: bold;
}

.specification-item-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 8px;
}

.specification-active {
  margin-top: 15px;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 4px;
  color: #333333;
  border: 1px solid #000000;
  /*background-color: #efefef;*/
}
.used-price {
  text-decoration: line-through;
  display: inline-block;
  border: 1px solid #999999;
  color: #999999;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 15px;
  padding: 5px 4px;
  border-radius: 5px;
}

.title-bar {
  width: 100%;
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  margin-top: 5px;
  color: #484848;
  background-color: #fff;
  line-height: 30px;
  text-align: left;
  text-indent: 10px;
}

.price {
  width: 100%;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: #dd1a21;
  font-size: 15px;
  background-color: #fff;
  padding: 0 8px;
  text-align: left;
}

.price-del {
  text-decoration: line-through;
  font-size: 15px;
  color: #b5b5b5;
  font-weight: 500;
}

.recommend {
  width: 100%;
  display: flex;
  color: #9c9c9c;
  background-color: #fff;
  justify-content: space-between;
  font-size: 15px;
  padding: 0 10px 5px;
}

.excuse {
  font-family: "YouYuan";
  font-weight: 600;
  background-color: #fff;
  border-top: 1px solid #f2f2f2;
  font-size: 16px;
  color: #484848;
  padding: 5px 10px 5px;
}

.message-box {
  position: relative;
  width: 95%;
  background-color: #fafafa;
  font-size: 15px;
  /* margin-top: 10px; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 5px auto;
  padding: 10px 15px;
}

.message-box-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px auto;
}

.actively {
  color: #D88427;
  border: 1px solid #D88427;
  /*background-color: #D88427 !important;*/
}

.tb-background {
  top: -9px;
  border-color: transparent transparent #fafafa transparent;
}

.triangle-border {
  position: absolute;
  left: 10px;
  overflow: hidden;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: none dashed solid dashed;
}

.message-box-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px auto;
  width: 100%;
}

.detail-img {
  width: 100%;
  margin-top: 15px;
  padding-bottom: 45px;
}

.function_box {
  display: flex;
  justify-content: space-between;
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}

.function_btn1 {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  line-height: 20px;
  background-color: #fff;
  color: #666666;
  padding-top: 5px;
  position: relative;
  font-size: 14px;
}

.function_btn {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  line-height: 20px;
  color: #fff;
  padding-top: 5px;
  position: relative;
  font-size: 14px;
  background: #e85d64;
}

.function_btn img {
  width: 24px;
  height: 24px;
}

.product-image {
  display: flex;
  padding-top: 13px;
}

.image-left img {
  margin-top: 3px;
  margin-left: 10px;
  border-radius: 5px;
  width: 120px;
  height: 120px;
}

.image-left {
  flex: 1;
}

.right-new-used-row {
  display: flex;
  margin-top: 5px;
}

.right-num span {
  font-size: 14px;
  color: #666666;
  margin-top: 5px;
}
.right-num{
  box-sizing: border-box;
  padding-right: 10px;
}
.tip{
  font-size: 13px;
  /*font-family: "Microsoft Sans Serif" !important;*/
  font-weight: 500;
  color: #1c1a1b;
  line-height: 22px;
}

.image-right {
  margin-left: 15px;
  flex: 2;
}

.product-num {
  width: 150px;
}

.product-row1 {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: flex-end;
  padding-right: 10px;
  font-size: 16px;
}

.product-row1 span {
  padding-top: 5px;
}

img {
  width: 100%;
}

.function_btn1 img {
  width: 20px;
  height: 20px;
}

a:hover {
  color: #666666;
}

.center {
  text-align: center;
  align-items: center;
  margin: 20px auto 30px;
}
.product-specification{
  margin: 10px 0px 20px 0px;
}
.Alibaba-PuHuiTi-Regular{
  font-family: "Alibaba-PuHuiTi-Regular";
}

.Alibaba-PuHuiTi-Bold{
  font-family: "Alibaba-PuHuiTi-Bold";
}
 .subtract,.add{
   position: relative;
 }
.subtract::after,
.add::before,
.add::after{
  content: '';
  position: absolute;
  right: 50%;
  top: 45%;
  transform: translate(50%,50%);
  width: 15px;
  border-top: 2px solid;
}

.add::after {
  transform:translate(50%,50%) rotate(90deg);
}
</style>
