<template>
  <div class="container">
    <div class="user-info">
      <div class="avatar"></div>
      <div class="user-name">随风追逐</div>
    </div>
    <div class="summary">
      <div>
        我的团：<span style="color: #000; font-weight: bold">28344人</span>
      </div>
      <div>
        佣金：<span style="color: #df0f2a; font-weight: bold">842424元</span>
      </div>
    </div>
    <div class="summary-detail">
      <div class="summary-detail-header">
        <div style="width: 40px"></div>
        <div class="flex-1">一级</div>
        <div class="flex-1">二级</div>
        <div class="flex-1">三级</div>
      </div>
      <div class="summary-detail-item">
        <div style="width: 40px">队员</div>
        <div class="flex-1">8人</div>
        <div class="border-left flex-1">96人</div>
        <div class="border-left flex-1">1328人</div>
      </div>
      <div class="summary-detail-item">
        <div style="width: 40px">用户</div>
        <div class="flex-1">84人</div>
        <div class="border-left flex-1">127人</div>
        <div class="border-left flex-1">5232人</div>
      </div>
      <div class="summary-detail-item">
        <div style="width: 40px">本月</div>
        <div class="flex-1">150元</div>
        <div class="border-left flex-1">15320元</div>
        <div class="border-left flex-1">245150元</div>
      </div>
      <div class="summary-detail-item">
        <div style="width: 40px">上月</div>
        <div class="flex-1">150元</div>
        <div class="border-left flex-1">15320元</div>
        <div class="border-left flex-1">245150元</div>
      </div>
    </div>
    <div class="tool">
      <div class="tool-item">
        <img src="../assets/rule.svg" />
        <div class="tool-title">规则</div>
      </div>
      <div class="tool-item">
        <img src="../assets/qrcode.svg" />
        <div class="tool-title">邀请码</div>
      </div>
      <div class="tool-item">
        <img src="../assets/withdraw.svg" />
        <div class="tool-title">提现</div>
      </div>
      <div class="tool-item">
        <img src="../assets/fankui.svg" />
        <div class="tool-title">效果反馈</div>
      </div>
      <div class="tool-item">
        <img src="../assets/jianyi.svg" />
        <div class="tool-title">问题及建议</div>
      </div>
      <div class="tool-item">
        <img src="../assets/clean.svg" />
        <div class="tool-title" @click="clearCache">清除缓存</div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_USER_INFO_MUTATION } from "../mutation-types";
import { Toast } from "vant";
import { Api } from "../services/api";
export default {
  name: "User",
  data() {
    return {};
  },
  methods: {
    clearCache() {
      Toast.loading("清除缓存中...");
      Api.clearCache().then(() => {
        Toast.clear();
        this.$store.commit({
          type: SET_USER_INFO_MUTATION,
          payload: null,
        });
        this.fetchUser();
      });
    },
    fetchUser(callback) {
      Api.getUserCenter().then((data) => {
        callback && callback();
        this.$store.commit({
          type: SET_USER_INFO_MUTATION,
          payload: data.data,
        });
      });
    },
  },
  computed: {
    user() {
      return this.$store.state.user || {};
    },
  },
  mounted() {
    // this.fetchUser();
    document.title = "会员中心";
  },
};
</script>

<style scoped>
body {
  height: 100%;
  background: #ebeaea;
}

.container {
  display: flex;
  flex-direction: column;
}

.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 90px;
  width: 96%;
  box-sizing: border-box;
  background: #fff;
  margin: 10px 2%;
  border-radius: 10px;
}

.avatar {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-left: 10px;
  background: #ebeaea;
}

.user-name {
  font-size: 18px;
  color: #333;
  margin-left: 10px;
}

.summary {
  width: 96%;
  margin: 10px 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  height: 45px;
  padding: 0 30px;
}

.summary-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  margin-top: 20px;
  padding: 0 20px;
  font-size: 14px;
}

.summary-detail-header {
  display: flex;
  flex-direction: row;
  height: 35px;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
}
.summary-detail-header div {
  display: flex;
  text-align: center;
  justify-content: center;
}
.summary-detail-item {
  display: flex;
  height: 35px;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.summary-detail-item div {
  display: flex;
  text-align: center;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.border-left {
  border-left: 1px solid #d8d8d8;
}
.flex-1 {
  flex: 1;
}
.tool {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #fff;
  width: 96%;
  margin: 30px 2% 0 2%;
  border-radius: 10px;
  padding-bottom: 20px;
}
.tool-item {
  width: 22.6%;
  margin-left: 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50px;
  background: #f1f0f0;
  margin-top: 20px;
}

.tool div img {
  width: 40%;
  height: auto;
  margin-top: 5px;
}

.tool-title {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  margin-top: 5px;
}
</style>
