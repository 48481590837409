import Product from "./components/Product.vue";
import User from "./components/User.vue";
import OrderList from "./components/OrderList.vue";
import UserAddress from "./components/UserAddress.vue";
import UserAddressEdit from "./components/UserAddressEdit.vue";
import UserAddressAdd from "./components/UserAddressAdd.vue";
import OrderDetail from "./components/OrderDetail.vue";
import OrderConfirm from "./components/OrderConfirm.vue";
import Pay from "./components/Pay.vue";
import RefundConfirm from "./components/RefundConfirm.vue";
import Saleman from "./components/saleman/Index.vue";
import SalemanWithdraw from "./components/saleman/Withdraw.vue";
import SaleBill from "./components/saleman/Bill.vue";
import SaleManager from "./components/saleman/Manager.vue";
import RefundDetail from "./components/RefundDetail.vue";
import Express from "./components/Express.vue";
import Vip from "./components/Vip.vue";
import Article from "./components/Article.vue";
import BillDoctor from "./components/saleman/BillDoc.vue";
import VipCenter from "./components/VipCenter.vue";
import { createRouter, createWebHistory } from "vue-router";


const routes = [
  { path: "/", component: Product },
  { path: "/user", component: User },
  {
    path: "/user/orders/:status",
    component: OrderList,
    name: "userOrders",
  },
  {
    path: "/user/address",
    name: "userAddress",
    component: UserAddress,
  },
  {
    path: "/user/address/:id",
    name: "userAddressEdit",
    component: UserAddressEdit,
  },
  {
    path: "/user/address/add",
    name: "userAddressAdd",
    component: UserAddressAdd,
  },
  {
    path: "/orders/:id",
    name: "orderDetail",
    component: OrderDetail,
  },
  {
    path: "/order-confirm",
    name: "orderConfirm",
    component: OrderConfirm,
  },
  {
    path: "/pay/:id",
    name: "pay",
    component: Pay,
  },
  {
    path: "/refund-confirm/:id",
    name: "refundConfirm",
    component: RefundConfirm,
  },
  {
    path: "/saleman",
    name: "saleman",
    component: Saleman,
  },
  {
    path: "/saleman/withdraw",
    name: "salemanWithdraw",
    component: SalemanWithdraw,
  },
  {
    path: "/saleman/bill",
    name: "salemanBill",
    component: SaleBill,
  },
  {
    path: "/saleman/bill/:id",
    name: "salemanBillId",
    component: SaleBill,
  },
  {
    path: "/saleman/manager",
    name: "salemanManager",
    component: SaleManager,
  },
  {
    path: "/refund-detail/:id",
    name: "refundDetail",
    component: RefundDetail,
  },
  {
    path: "/order/express/:id",
    name: "orderExpress",
    component: Express,
  },
  {
    path: "/user/vip",
    name: "userVip",
    component: Vip,
  },
  {
    path: "/article/:id",
    name: "article",
    component: Article,
  },
  {
    path: "/saleman/bill-doctor",
    name: "billDoctor",
    component: BillDoctor,
  },
  {
    path: "/vip-center",
    name: "vipCenter",
    component: VipCenter,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
