<template>
  <van-nav-bar
    :title="title"
    left-text="返回"
    left-arrow
    @click-left="onClickLeft"
    style="position: fixed; top: 0; left: 0; width: 100%; z-index: 1"
  />
</template>

<script>
export default {
  name: "Nav",
  props: {
    title: {
      type: String,
      default: "标题",
    },
  },
  methods: {
    onClickLeft() {
      const routes = this.$router.options.history.state;
      if (this.$route.fullPath.indexOf("/user/orders") > -1) {
        this.$router.replace("/user");
        return;
      }
      if (!routes.back) {
        this.$router.replace("/");
      }
      if (routes.back === "/user" || routes.back === "/") {
        this.$router.replace(routes.back);
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style scoped></style>
