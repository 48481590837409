<template>
  <Nav title="修改地址" />

  <div class="main">
    <div id="form">
      <div class="consignee">
        <div class="consignee-name">姓名:</div>
        <input
          type="text"
          class="consignee-info"
          v-model="username"
          placeholder="收货人姓名"
          maxlength="10"
        />
      </div>
      <div class="consignee">
        <div class="consignee-name">电话:</div>
        <input
          maxlength="11"
          type="tel"
          class="consignee-info"
          v-model="phone"
          placeholder="收货手机号码"
        />
      </div>

      <div class="consignee">
        <div class="consignee-name">地区:</div>
        <input
          type="text"
          id="adrs"
          class="consignee-info"
          v-model="area"
          placeholder="选择省/市/区"
          readonly
          required
          @click="showArea()"
        />
        <van-popup v-model:show="showPicker" round position="bottom">
          <van-picker
            title="选择城市"
            :columns="cities"
            :columns-field-names="{ text: 'name', children: 'children' }"
            @cancel="showPicker = false"
            @confirm="handleConfirmArea"
          />
        </van-popup>
      </div>
      <div class="consignee">
        <div class="consignee-name">详细地址:</div>
        <input
          type="text"
          class="consignee-info"
          v-model="addrs"
          placeholder="小区名称/写字楼/楼号/门牌号等"
          maxlength="50"
        />
      </div>
      <div class="for-but">
        <button @click="saveAddress" class="login-button" type="submit">
          保存地址
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import Nav from "./common/Nav.vue";
import {} from "../mutation-types";
import Citys from "../services/citys";
import { Api } from "../services/api";

export default {
  setup() {
    return {
      cities: Citys,
    };
  },
  data() {
    return {
      showPicker: false,
      area: null,
      username: null,
      phone: null,
      addrs: null,
    };
  },
  components: {
    Nav,
  },
  methods: {
    showArea() {
      this.showPicker = true;
    },
    handleConfirmArea(value) {
      console.log(value);
      if (value[1].name === "市辖区") {
        this.area = value[0].name + value[2].name;
      } else {
        this.area = value[0].name + value[1].name + value[2].name;
      }
      this.showPicker = false;
    },
    saveAddress() {
      Api.saveAddress(this.id, {
        username: this.username,
        phone: this.phone,
        area: this.area,
        addrs: this.addrs,
      })
        .then((res) => {
          console.log(res);
          if (res.status) {
            Toast.success("保存成功");
            this.$router.back();
          } else {
            Toast.fail(res.msg);
          }
        })
        .catch(() => {
          Toast.fail("网络错误");
        });
    },
  },
  mounted() {
    Api.getAddressDetail(this.id).then((res) => {
      this.username = res.data.username;
      this.phone = res.data.phone;
      this.area = res.data.area;
      this.addrs = res.data.addrs;
    });
  },
  computed: {
    userAddressList() {
      return this.$store.state.userAddressList;
    },
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped>
.body {
  text-align: left;
}
.addr-default {
  color: #d8585e;
  border: 1px solid #d8585e;
  text-align: center;
  border-radius: 20px;
  padding: 2px 5px;
  margin-left: 10px;
  font-size: 12px;
}

.info-address {
  font-size: 14px;
  color: #666666;
  border-bottom: 1px solid #ededed;
  width: 94%;
  margin: 0 auto;
  padding: 0 0 5px;
  text-align: left;
}

.info {
  margin-top: 5px;
  font-size: 15px;
  background-color: #fff;
  padding-bottom: 10px;
}

.info-row {
  display: flex;
  padding: 5px 10px 3px;
}
.info-name {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
}
.info-phone {
  font-size: 14px;
  padding-left: 10px;
  color: #666666;
}
.info-address {
  font-size: 14px;
  color: #666666;
  border-bottom: 1px solid #ededed;
  width: 94%;
  margin: 0 auto;
  padding: 0 0 5px;
}

.main {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-top: 50px;
}

.consignee {
  display: flex;
  padding: 10px 0;
}

.consignee-name {
  padding: 5px 15px;
  color: #333333;
  font-size: 15px;
  font-weight: bold;
  flex: 1;
}

.consignee-info {
  flex: 8;
  border: #fff;
  font-size: 15px;
  border-bottom: 1px solid #f8f8f8;
  outline: none;
  color: #666666;
}

.for-but {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.login-button {
  border-width: 0px;
  border-radius: 20px;
  padding: 8px 40px;
  background: #d8585e;
  cursor: pointer;
  outline: none;
  color: #ecf5ff;
  font-size: 16px;
  margin: 30px auto;
}
</style>
